<template>
  <v-card>
    <v-card-title>
      {{$t('deliveryService.lang_productGroups')}}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-container class="ma-0 pa-0" fluid>
      <v-card elevation="0" outlined tile>
        <v-card-text class="ma-0 pt-4 pa-0">
          <v-card elevation="0">
            <v-card-title class="pa-0 ma-0">
              <v-row>
                <v-col class="pt-0 ma-0" cols="12">
                  <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <b-input-group width="100%">
                        <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :placeholder="$t('datatables.lang_search')"
                                      @focus="showTouchKeyboard"
                                      v-model="search"/>
                      </b-input-group>
                    </div>

                    <div class="btn-actions-pane-right actions-icon-btn">
                      <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                        <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
                        <div>
                          <h6 class="dropdown-header" tabindex="-1">
                            {{ $t('generic.lang_Options') }}</h6>
                          <button :disabled="(selectedRows && selectedRows.length === 0)"
                                  class="dropdown-item" tabindex="0" type="button" @click="showDiscountDialog=true">
                            <v-icon color="success" class="dropdown-icon">
                              mdi-percent
                            </v-icon>
                            <span>{{$t('generic.lang_discount')}}</span>
                          </button>
                        </div>
                      </b-dropdown>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table
                :footer-props="footerProps"
                :headers="filteredDatatableHeaders"
                :items="filteredDatatableEntries"
                :loading="loading"
                sortBy="position"
                :options.sync="pagination"
                :server-items-length="total"
                footer-props.items-per-page-text="Einträge pro Seite"
            >
              <template v-slot:header.crud="{header}">
                <v-checkbox class="ma-0" hide-details @change="toggleAll($event)" :value="selectedRows&&selectedRows.length>0" :indeterminate="filteredDatatableEntries.length>selectedRows.length&&selectedRows.length>0"  />
              </template>
              <template v-slot:body="props" >
                <draggable
                    :list="props.items"
                    tag="tbody"
                    v-bind="dragOptions"
                    @end="changePosition($event)"
                >
                  <tr
                      v-for="(waregroup, index) in props.items"
                      :key="index"
                  >
                    <td>
                      <!--<font-awesome-icon :icon="['fas','grip-vertical']" style="color:lightgray;cursor: pointer;" class="page__grab-icon text-lighter handle"/>-->
                      <v-checkbox class="ma-0" hide-details @change="toggle($event,waregroup)" :value="selectedRows.includes(Number(waregroup.id))" />
                    </td>
                    <td v-show="false">
                      {{waregroup.id}}
                    </td>

                    <td> {{waregroup.category}} </td>
                    <td>
                      <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                    @blur="editEntry(waregroup)" @keypress.enter="editEntry(waregroup)"
                                    v-model="waregroup.localbeeDiscount" type="number" prefix="%" @keyup="discountInput(waregroup,'localbeeDiscount')"/>
                    </td>
                    <td>
                      <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                    type="number"
                                    @blur="editEntry(waregroup)" @keypress.enter="editEntry(waregroup)"
                                    v-model="waregroup.position"/>
                    </td>
                    <td>
                      <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                    type="number"
                                    @blur="editEntry(waregroup)" @keypress.enter="editEntry(waregroup)"
                                    v-model="waregroup.lb_take_away_discount"/>
                    </td>
                    <td class="d-flex justify-center">
                      <v-switch v-model="waregroup.state"  hide-details @change="editEntry(waregroup)" class="ma-0"/>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-card>
          <v-dialog max-width="500" persistent v-model="showDiscountDialog">
            <div class="card">
              <div class="card-header pt-3">
                <div class="card-title">{{$t('generic.lang_discount')}}</div>
              </div>

              <div class="card-body">
                <v-form lazy-validation v-model="valid" ref="discountForm" >
                  <v-text-field v-model="lbItemGroupDiscount" type="number"  outlined dense :rules="[v=>Number(v)>=0]"  :label="$t('generic.lang_deliveryDiscount')"  :data-layout="KEYBOARD.KEYSETS.NORMAL" @keyup="discount('lbItemGroupDiscount')"
                                @focus="showTouchKeyboard">

                  </v-text-field>

                  <v-text-field v-model="lb_take_away_discount" type="number" outlined dense :label="$t('generic.lang_takeAwayDiscount')" :rules="[v=>Number(v)>=0]" :data-layout="KEYBOARD.KEYSETS.NORMAL" @keyup="discount('lb_take_away_discount')"
                                @focus="showTouchKeyboard">

                  </v-text-field>

                </v-form>
              </div>
              <div class="card-footer text-right">
                <v-spacer/>
                <v-btn @click="showDiscountDialog = false" class="btn btn-danger bg-danger text-light mx-auto" >
                  {{this.$t('generic.lang_cancel')}}
                </v-btn>
                <v-btn :disabled="!valid||loading" :loading="loading" @click="applyDiscount()"
                       class="btn bg-primary text-light btn-primary mx-auto ml-2">
                  {{this.$t('generic.lang_save')}}
                </v-btn>
              </div>
            </div>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns"
import draggable from "vuedraggable";
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faGripVertical} from '@fortawesome/pro-solid-svg-icons'

library.add(faGripVertical);


export default {
  name: "ProductGroupsComponent",
  components: {
    draggable,
    'font-awesome-icon':FontAwesomeIcon,
  },
  mixins: [mixin],
  data() {
    return {
      /*
      * lb_take_away_discount:
self_service_alias:
self_service_discount:
self_service_active:
* array(
 'id', 'cat_name_de', 'lbWareCatName', 'lbWareGroupDiscount', 'lbWareCatActive', 'lbWareCatPosition',
 'lb_take_away_discount', 'self_service_alias', 'self_service_discount', 'self_service_active'
)

      * */
      datatableHeaders: [
        {
          text:'',
          value:'crud',
          sortable:false,
          width: 50,
        },
        {
          text: 'ID',
          value: 'id',
          hidden:true,
        },
        {
          text: this.$t('erp.lang_itemgroup_name'),
          value: 'category',
        },
        {
          text: this.$t('generic.lang_deliveryDiscount'),
          value: 'localbeeDiscount',
          width:'100',
          align:'center',
        },
        {
          text: this.$t('generic.lang_sorting'),
          value: 'position',
          align:'center',
          width:'100',

        },
        {
          text: this.$t('generic.lang_takeAwayDiscount'),
          value: 'lb_take_away_discount',
          width:'100',
        },
        {
          text: this.$t('erp.lang_waregroupActive'),
          value: 'state',
          align:'right',
        },
      ],
      loading: false,
      valid: false,
      drag:false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [
        [1,'cat1','localbee cat',50,1,2],
        [2,'cat2','localbee brot',10,1,3],
        [3,'cat3','localbee snacks',10,1,4],
        [4,'cat4','localbee cakes',10,1,5],
        [5,'cat5','localbee ice cream',10,1,6],
      ],
      search: '',
      total: 10,
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      showDiscountDialog: false,
      lbItemGroupDiscount:null,
      lb_take_away_discount:null,
    }
  },
  computed: {
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hidden")) {
          if (header.hidden) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;

    },
    filteredDatatableEntries: function () {
      let dataToRender = [];
      if(this.dataToShow)
        this.dataToShow.forEach((data) => {
          let row = {};
          let index=0;
          this.datatableHeaders.forEach((header) => {
            if(header.value!=='crud'){
              if(header.value==='position'){
                row[header.value] = parseInt(Number(data[index]));
              }else{
                row[header.value] = data[index];
              }
            }else{
              return
            }
            index++;

          });
          dataToRender.push(row);
        });

      return dataToRender;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    toggleAll(val){
      if(val&&(this.selectedRows&&this.selectedRows.length<=0)){
        this.selectedRows.push(...this.filteredDatatableEntries.map(wareGroup=>Number(wareGroup.id)))
      }else{
        this.selectedRows=[];
      }
    },
    toggle(val,entry){

      let index = this.selectedRows.findIndex(id=>Number(entry.id)===id);
      if(val){
        if(this.selectedRows.includes(Number(entry.id))){
          this.$delete(this.selectedRows,index);
        }else{
          this.selectedRows.push(Number(entry.id));
        }
      }else{
        if(this.selectedRows.includes(Number(entry.id))){
          this.$delete(this.selectedRows,index);
        }
      }

    },
    discountInput(item,attr){
      if (Number(item[attr] ) > 100) {
        item[attr] = 100;
      }
    },
    discount(item){
      if (Number(this[item] ) > 100) {
        this[item] = 100;
      }else if(Number(this[item] )<0){
        this[item] = 0;
      }
    },
    editEntry(entry,event=null) {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PRODUCTGROUPS.UPDATE,{
        id:entry.id,
        lbItemGroupDiscount:parseFloat(Number(entry.localbeeDiscount)),
        lbItemGroupState:entry.state,
        lbItemGroupPosition:entry.position,
        lb_take_away_discount:entry.lb_take_away_discount,
        self_service_alias:entry.self_service_alias,
        self_service_discount:entry.self_service_discount,
        self_service_active:entry.self_service_active,
      }).then(res=>{
        if(res.data.status==="SUCCESS")
        {
          this.getDataFromApi();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status,
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      })
    },
    applyDiscount() {
      let form={}

      if(this.lbItemGroupDiscount){
        form.lbItemGroupDiscount=Number(this.lbItemGroupDiscount);
      }
      if(this.lb_take_away_discount){
        form.lb_take_away_discount=Number(this.lb_take_away_discount);
      }
      form.id=this.selectedRows;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PRODUCTGROUPS.BULKACTION.DISCOUNT,form).then(res=>{
        if(res.data.status==="SUCCESS")
        {
          this.getDataFromApi();
          this.showDiscountDialog=false;
          this.$refs.discountForm.reset();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status,
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{

      })
    },
    changePosition(event){
      let item=this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced=this.filteredDatatableEntries[event.newIndex]
      if(event.oldIndex<event.newIndex){

        if(item.id!==itemReplaced.id)
          item.position=parseInt(Number(itemReplaced.position))+1;

      }else if(event.oldIndex>event.newIndex){

        if(item.id!==itemReplaced.id)
          if(parseInt(Number(itemReplaced.position))-1===0){

            item.position=1;

          }else{

            item.position=parseInt(Number(itemReplaced.position))-1;

          }

      }
      if(item.id!==itemReplaced.id){
        this.editEntry(item);
      }

    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);
        index-=1
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }

      /* SPECIFY ADDITIONAL PARAMS
      * params['customerID'] = parseInt(this.$props.customerId);
      *
      * */

      this.axios.get(ENDPOINTS.DATATABLES.DELIVERY.PRODUCTGROUPS, {
        params: params
      }).then((res) => {

        this.total = parseInt(Number(res.data.iTotalDisplayRecords));
        this.dataToShow = res.data.aaData;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
        this.dataToShow = [];
      }).finally(fin=>{
        this.loading = false;
      })
    },
  },
  mounted() {
    this.getDataFromApi();
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
