<template>
  <v-card>
    <v-card-title>
        {{ $t('tablebee.lang_qr_code_generator') }}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text>
    <v-data-table
        :headers="headers"
        :items="tables"
        :search="search"
        v-model="selectedItems"
        item-key="name"
        show-select

    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="5">
            <v-text-field
                v-model="search"
                :label="$t('tablebee.lang_search_table')"
                class="mx-4"
                outlined
                dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" >
            <v-select
                v-model="selectedRoom"
                :label="$t('tablebee.lang_select_room')"
                class="pt-0"
                :items="room"
                outlined
                dense
                @input="filterByRoom()"
            >
              <template v-slot:item="{ item }"> <span>{{item[1]}}</span></template>
              <template v-slot:selection="{ item }"> <span>{{item[1]}}</span></template>

            </v-select>
          </v-col>
          <v-col cols="12" sm="2" v-if="selectedItems.length>0" >
            <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon dark>
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                >
                  <v-btn
                      class="ma-2"
                      outlined
                      :color="item.color"
                      @click="getQRCode(selectedItems.map(item=>{
                        return {name:item.name}
                      }),item.title.toLocaleLowerCase())"
                  >
                    <v-icon dark>
                      {{ item.icon }}
                    </v-icon>
                    {{ item.title }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-if="selectedItems.length==0" v-slot:item.png="{ item }">
        <v-btn
            fab
            dark
            small
            color="success"
            @click="getQRCode([{name:item.name}],'png')"
        >
          <v-icon dark>
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <template v-if="selectedItems.length==0" v-slot:item.svg="{ item }">
        <v-btn
            fab
            dark
            small
            color="warning"
            @click="getQRCode([{name:item.name}],'svg')"
        >
          <v-icon dark>
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <template v-if="selectedItems.length==0" v-slot:item.pdf="{ item }">
        <v-btn
            fab
            dark
            small
            color="error"
            @click="getQRCode([{name:item.name}],'pdf')"
        >
          <v-icon dark>
            mdi-download
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';

export default {
  name: "QrCodeGenerator",
  data () {
    return {
      search: '',
      selectedItems: [],
      calories: '',
      room : [],
      selectedRoom :null,
      items: [
        { title: 'PNG', icon :  'mdi-file-image', color : 'warning'},
        { title: 'SVG' ,icon : 'mdi-file-image' , color : 'success'},
        { title: 'PDF',icon : 'mdi-file-pdf' ,color : 'error' },
      ],
      tablesGlobal : [
        {
          name: 'Yassin',
          TotalParties: 1,
          room : 'Hauptraum'
        },
        {
          name: 'Oussam',
          TotalParties: 1,
          room : 'Hauptraum'
        },
        {
          name: 'Omar',
          TotalParties: 1,
          room : 'Salon'
        }
        ],
      tables: [
        {
          name: 'Yassin',
          TotalParties: 1,
          room : 'Hauptraum'
        },
        {
          name: 'Oussam',
          TotalParties: 1,
          room : 'Hauptraum'
        },
        {
          name: 'Omar',
          TotalParties: 1,
          room : 'Salon'
        }
        ]

    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('generic.lang_name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('generic.lang_party'),
          value: 'TotalParties',
        },
        { text: ' PNG', value: 'png', sortable: false,align: 'center' },
        { text: ' SVG', value: 'svg', sortable: false,align: 'center'  },
        { text: ' PDF', value: 'pdf', sortable: false,align: 'center'  },

      ]
    },
  },
  mounted() {
    this.getData();
    this.getRoom();
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().indexOf(search) !== -1
    },
    filterByRoom(){
      this.tables =this.tablesGlobal.filter(item => item.room== this.selectedRoom[1]);
    },
    getData(){
      this.loading=true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETALL)
          .then(res=>{
           this.tables = res.data.tables;
           this.tablesGlobal =res.data.tables;
          }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loading=false;
      });
    },
    getRoom(){
      this.loading=true;
      this.axios.post('get/settings/allRooms/')
          .then(res=>{
            this.room = res.data.roomInformations;
          }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loading=false;
      });
    },
    getQRCode(table,type){
      let formData={}
      let blobType='';
      let name='';
   //   console.log(table,type)
      switch (type){
        case 'png':
          formData.tableName=table;
          formData.type=type;
          blobType=(table.length>1)?"application/zip":'image/png';
          break;
        case 'svg':
          formData.tableName=table;
          formData.type=type;
          blobType=(table.length>1)?"application/zip":'image/svg';
          break;
        case 'pdf':
          formData.tableName=table;
          formData.type=type;
          blobType='application/pdf';
          break;
      }

      if(table.length>1 && type!=='pdf'){
        name='localbee_table_QRCODES.zip'
      }else if(table.length>1 && type==='pdf'){
        name='localbee_tables_QRCODES.pdf'
      }else {
        name=table[0].name+'.'+type;
      }
   //   console.log(table,type,name)

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.QRCODE,formData,{responseType: 'arraybuffer'})
          .then(res=>{
            if(res.status===200){
              const blobContent = new Blob([res.data], {type:blobType});
              FileSaver.saveAs(new Blob([res.data], {
                type: blobType
              }), name);
            }
          }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      })

    },
  },
}
</script>

<style scoped>

</style>
