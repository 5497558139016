<template>
  <v-card>
    <v-card-title>
      {{ $t("generic.lang_products") }}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-container class="ma-0 pa-0" fluid>
      <v-card elevation="0" outlined tile>
        <v-card-text class="ma-0 pt-4 pa-0">
          <v-card elevation="0">
            <v-card-title class="pa-0  ma-0">
              <v-row>
                <v-col class="pt-0 ma-0" cols="12">
                  <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <b-input-group width="100%">
                        <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :placeholder="$t('datatables.lang_search')"
                                      @focus="showTouchKeyboard"
                                      v-model="search"/>
                      </b-input-group>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0 pt-2">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete :label="$t('generic.lang_waregroup')" class="mx-1" outlined dense v-model="waregroupID"
                                  @change="getDataFromApi" hide-details clearable :items="filteredWareGroups"
                                  item-value="id" item-text="name"/>
                </v-col>
                <v-col cols="6">
                  <v-select v-if="waregroupID" :label="$t('generic.lang_bulkActions')" class="mx-1" @change="bulkAction" outlined dense
                            v-model="action" hide-details clearable :items="actions" item-value="id" item-text="name"/>
                </v-col>
              </v-row>
              <v-divider class="ma-1"/>
              <v-data-table
                  :footer-props="footerProps"
                  :headers="filteredDatatableHeaders"
                  :items="filteredDatatableEntries"
                  :loading="loading"
                  sortBy="position"
                  :options.sync="pagination"
                  :server-items-length="total"
                  footer-props.items-per-page-text="Einträge pro Seite"
                  v-model="selectedRows"
              >
                <template v-slot:body="props">
                  <draggable
                      :list="props.items"
                      tag="tbody"
                      v-bind="dragOptions"
                      @end="changePosition($event)"
                  >
                    <tr
                        v-for="(item, index) in props.items"
                        :key="index"
                    >
                      <td>
                        <font-awesome-icon :icon="['fas','grip-vertical']" style="color:lightgray;cursor: pointer;"
                                           class="page__grab-icon text-lighter handle"/>
                      </td>
                      <td> {{ item.product }}</td>
                      <td class="text-right">
                        {{ item.price | currency }}
                      </td>
                      <td> {{ item.department }}</td>
                      <td>
                        <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                      @blur="editEntry(item)" @keypress.enter="editEntry(item)"
                                      v-model="item.position" trim/>
                      </td>
                      <td>
                        <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                      @blur="editEntry(item)" @keypress.enter="editEntry(item)"
                                      v-model="item.lb_take_away_price" type="number"/>
                      </td>
                      <td>
                        <b-form-input :class="[$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                      @blur="editEntry(item)" @keypress.enter="editEntry(item)"
                                      v-model="item.lb_delivery_price" type="number"/>
                      </td>
                      <td>
                        <v-switch v-model="item.state" hide-details @change="editEntry(item)" :true-value="1" :false-value="0" class="ma-0"/>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns"
import draggable from "vuedraggable";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faGripVertical} from '@fortawesome/pro-solid-svg-icons'

library.add(faGripVertical);

export default {
  name: "ProductsComponent",

  components: {
    draggable,
    'font-awesome-icon': FontAwesomeIcon,
  },

  mixins: [mixin],

  data() {
    //'id', 'cat_name_de', 'lbWareCatName', 'lbWareGroupDiscount', 'lbWareCatActive', 'lbWareCatPosition','lb_take_away_discount'
    return {
      datatableHeaders: [
        {
          text: '',
          value: 'crud'
        },
        {
          text: 'ID',
          value: 'id',
          hidden: true,
        },
        {
          text: this.$t('generic.lang_product_name'),
          value: 'product',
        },
        {
          text: this.$t('generic.lang_originalPrice'),
          value: 'price',
          width: '100',
        },
        {
          text: this.$t('generic.lang_waregroup'),
          value: 'department',
        },
        {
          text: this.$t('generic.lang_sorting'),
          value: 'position',
          align: 'center',
          width: '80'
        },
        {
          text: 'tax_id',
          value: 'tax_id',
          align: 'center',
          hidden: true,
        },
        {
          text: this.$t('generic.lang_takeawayPrice'),
          value: 'lb_take_away_price',
          width: 100,
        },
        {
          text: this.$t('generic.lang_deliveryPrice'),
          value: 'lb_delivery_price',
          width: 100
        },

        {
          text: this.$t('generic.lang_status'),
          value: 'state',
          align: 'center',
        },
      ],
      loading: false,
      drag: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [
        [1, 'product1', 50, 'idk', 'localbee name1', true, 3],
        [2, 'product2', 10, 'wys', 'localbee name2', true, 3],
        [3, 'product3', 10, 'ptm', 'localbee name3', true, 3],
        [4, 'product4', 10, 'bw', 'localbee name4', true, 3],
        [5, 'product5', 10, 'btu', 'localbee name5', true, 3],
      ],
      search: '',
      total: 0,
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      waregroupID: null,
      actions: [
        {
          id: 1,
          name: this.$t('generic.lang_activateAllForDelivery'),
        },
        {
          id: 3,
          name: this.$t('generic.lang_deactivateAllForDelivery'),
        },
      ],
      action: null,
      groups: []
    }
  },
  computed: {
    filteredWareGroups() {
      return this.groups.filter(waregroup => {
        return true;
      })
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hidden")) {
          if (header.hidden) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;

    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};
        let index = 0;
        this.datatableHeaders.forEach((header) => {
          if (header.value !== 'crud') {
            if (header.value === 'price') {
              row[header.value] = Number(data[index].toString().replace('EUR', '').replace(',', '.'));
            } else {
              row[header.value] = data[index];
            }
          } else {
            return
          }
          index++;
        });
        dataToRender.push(row);
      });
      return dataToRender;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    }
  },
  methods: {
    editEntry(entry, event = null) {
      if (entry.state) {
        if (!Number(entry.lb_take_away_price)) {
          entry.lb_take_away_price = entry.price;
        }
        if (!Number(entry.lb_delivery_price)) {
          entry.lb_delivery_price = entry.price;
        }
      }

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PRODUCTS.UPDATE, {
        id: entry.id,
        itemActive: entry.state,
        position: entry.position,
        lb_take_away_price: entry.lb_take_away_price,
        lb_delivery_price: entry.lb_delivery_price,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          this.getDataFromApi()

        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg || res.data.status,
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      })
    },
    changePosition(event) {
      let item = this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced = this.filteredDatatableEntries[event.newIndex]
      if (event.oldIndex < event.newIndex) {

        if (item.id !== itemReplaced.id)
          item.position = parseInt(Number(itemReplaced.position)) + 1;

      } else if (event.oldIndex > event.newIndex) {

        if (item.id !== itemReplaced.id)
          if (parseInt(Number(itemReplaced.position)) - 1 === 0) {

            item.position = 1;

          } else {

            item.position = parseInt(Number(itemReplaced.position)) - 1;

          }

      }
      if (item.id !== itemReplaced.id) {
        this.editEntry(item);
      }

    },
    async getDataFromApi() {

      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);
        index -= 1;
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }

      params['category'] = this.waregroupID
      /* SPECIFY ADDITIONAL PARAMS
      * params['customerID'] = parseInt(this.$props.customerId);
      *
      * */

      this.axios.get(ENDPOINTS.DATATABLES.DELIVERY.PRODUCTS, {
        params: params
      }).then((res) => {
        this.total = parseInt(res.data.iTotalDisplayRecords);
        this.dataToShow = res.data.aaData;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
        this.dataToShow = [];
      }).finally(fin => {
        this.loading = false;
      })
    },
    bulkAction() {
      if (!this.action) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PRODUCTS.BULKACTION, {
        waregroupID: this.waregroupID,
        action: this.action,
      }).then(res => {
        if (res.data.STATUS === "SUCCESS") {
          this.getDataFromApi()
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg || res.data.status,
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  async mounted() {
    this.getDataFromApi();

    this.groups = await this.$store.dispatch("itemgroups/getItemgroups");
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
