<template>
	<v-card :loading="loading">
		<v-card-title>
			{{ $t("generic.lang_otherSettings") }}
		</v-card-title>
		<v-divider class="ma-0" />
		<v-card-text>
			<v-container fluid>
				<v-row>
					<v-col cols="12" md="6">
						<v-select item-value="value" item-text="name" dense outlined hide-details
							:label="this.$t('tablebee.lang_default_language')" v-model="defaultLang"
							:items="this.languages">
							<template v-slot:selection="{ item }">
								<div class="d-flex align-middle">
									<country-flag :country="item.flag" />
									<strong class="mx-3 align-middle  my-auto">
										{{ item.name }}
									</strong>
								</div>
							</template>
							<template v-slot:item="{ item }">
								<div class="d-flex align-middle">
									<country-flag :country="item.flag" />
									<strong class="mx-3 align-middle  my-auto">
										{{ item.name }}
									</strong>
								</div>
							</template>
						</v-select>
					</v-col>
					<v-col cols="12" md="6">
						<v-select :label="$t('generic.lang_notification_sound')" outlined dense item-value="id"
							item-text="name" :disabled="loading" :loading="loading" v-model="notificationSound"
							:items="sounds" return-object>
							<template v-slot:append-outer>
								<v-btn class="ma-0 mt-n1" fab x-small color="primary" :disabled="loading">
									<v-icon color="white" @click="playSound"> mdi-play </v-icon>
								</v-btn>
							</template>
						</v-select>
					</v-col>
					<v-col cols="11">
						<v-row no-gutters>
							<v-col cols="8">
								<v-subheader>
									{{ $t("settings.lang_deliveryPrepTime") }}
								</v-subheader>
							</v-col>
							<v-col cols="4">
								<v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
									@focus="showTouchKeyboard" autocomplete="off" dense outlined type="number" min="0"
									v-model="lb_deliveryPreparationTime"></v-text-field>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="8">
								<v-subheader>
									{{ $t("settings.lang_pickUpPrepTime") }}
								</v-subheader>
							</v-col>
							<v-col cols="4">
								<v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
									@focus="showTouchKeyboard" autocomplete="off" dense outlined type="number" min="0"
									v-model="lb_pickUpPreparationTime"></v-text-field>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="8">
								<v-subheader>
									{{ "Max " + $t("delivery.lang_deliveryTime") + " (" + $t("generic.lang_minutes") +
									")" }}
								</v-subheader>
							</v-col>
							<v-col cols="4">
								<v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
									@focus="showTouchKeyboard" autocomplete="off" dense outlined type="number" min="0"
									v-model="lb_maxDeliveryTime"></v-text-field>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="8">
								<v-subheader>
									{{ $t("erp.lang_Delivery_DeliverySurcharge") + " " + $t("erp.lang_tax_value") }}
								</v-subheader>
							</v-col>
							<v-col cols="4">
								<v-select hide-details outlined dense class="" :items="taxes" return-object
									item-text="name" v-model="tax" />
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('delivery.lang_offerPickupInRestaurant')" v-model="pickup" value="1" />
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('delivery.lang_offerDelivery')" v-model="delivery" value="1" />
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('delivery.lang_show_orders_date_filter')"
							v-model="lb_show_orders_date_filter" :value="true" />
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('delivery.lang_enableOrderScheduling')"
							v-model="lb_enableOrderScheduling" value="1" />
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('delivery.lang_maintenanceModeActive')" v-model="maintenance"
							value="1" />
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('delivery.lang_disableZipCodeCheck')" v-model="disableZipCodeCheck"/>
					</v-col>
				</v-row>
				<v-divider />
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="6">
						<v-switch :label="$t('tablebee.lang_hide_product_details')" v-model="hideProductInfo" />
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('tablebee.lang_show_product_info')" v-model="showStandardProductInfo" />
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('tablebee.lang_show_allergens')" v-model="showAlergens" />
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6">
						<v-checkbox :label="$t('tablebee.lang_show_product_description')" v-model="showProductInfo" />
					</v-col>
					<v-col cols="12">
						<b-tabs>
							<b-tab active>
								<template #title>
									<v-btn text class="ma-0">
										<country-flag country="DE" />
									</v-btn>
								</template>
								<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']">
									<v-row no-gutters>
										<v-col class="" cols="12">
											<v-subheader><strong>{{ $t("tablebee.lang_standart_product_info")
													}}</strong></v-subheader>
											<quill-editor id="privacy_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
												:options="quillOptions" @focus="showTouchKeyboard" output="html"
												class="mb-12" v-model="standardProductInfo_de">
											</quill-editor>
										</v-col>
									</v-row>
								</b-card>
							</b-tab>

							<b-tab>
								<template #title>
									<v-btn text class="ma-0">
										<country-flag country="GB" />
									</v-btn>
								</template>
								<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']">
									<v-row no-gutters>
										<v-col class="" cols="12">
											<v-subheader><strong>{{ $t("tablebee.lang_standart_product_info")
													}}</strong></v-subheader>
											<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
												:options="quillOptions" @focus="showTouchKeyboard" output="html"
												class="mb-12" v-model="standardProductInfo_en">
											</quill-editor>
										</v-col>
									</v-row>
								</b-card>
							</b-tab>

							<b-tab>
								<template #title>
									<v-btn text class="ma-0">
										<country-flag country="FR" />
									</v-btn>
								</template>
								<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']">
									<v-row no-gutters>
										<v-col class="" cols="12">
											<v-subheader><strong>{{ $t("tablebee.lang_standart_product_info")
													}}</strong>
											</v-subheader>
											<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
												:options="quillOptions" @focus="showTouchKeyboard" output="html"
												class="mb-12" v-model="standardProductInfo_fr">
											</quill-editor>
										</v-col>
									</v-row>
								</b-card>
							</b-tab>

							<b-tab>
								<template #title>
									<v-btn text class="ma-0">
										<country-flag country="SA" />
									</v-btn>
								</template>
								<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']">
									<v-row no-gutters>
										<v-col class="" cols="12">
											<v-subheader><strong>{{ $t("tablebee.lang_standart_product_info")
													}}</strong></v-subheader>
											<quill-editor id="privacy_editor_4" :data-layout="KEYBOARD.KEYSETS.NORMAL"
												:options="quillOptions" @focus="showTouchKeyboard" output="html"
												class="mb-12" v-model="standardProductInfo_ar">
											</quill-editor>
										</v-col>
									</v-row>
								</b-card>
							</b-tab>
						</b-tabs>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
		<v-divider class="ma-0" />
		<v-card-actions class="pr-6">
			<v-row no-gutters>
				<v-col cols="12" align="end">
					<v-btn large :loading="loading" @click="update" color="success">
						{{ $t("generic.lang_save") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
		<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
			<vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
				:defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input" :layout="touchKeyboard.layout"
				:options="touchKeyboard.options" class="internalWidth" id="onScreenKeyboard"
				v-if="touchKeyboard.visible" />
		</div>
	</v-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import { Events } from "../../../plugins/events";
import { ENDPOINTS } from "../../../config";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import CountryFlag from "vue-country-flag";
import { quillOptions } from "@/plugins/quillSetup.js";

export default {
	name: "OtherSettingsComponent",
	components: {
		quillEditor,
		CountryFlag,
	},
	mixins: [mixin],
	data() {
		return {
			ENDPOINTS,
			loading: false,
			lb_deliveryPreparationTime: null,
			lb_pickUpPreparationTime: null,
			lb_maxDeliveryTime: null,
			notificationSound: 0,
			sounds: [
				{
					id: 0,
					name: this.$t("generic.lang_none"),
					src: "",
				},
				{
					id: 1,
					name: "mixkit-bell-notification-933",
					src: require("@/assets/sounds/mixkit-bell-notification-933.wav"),
				},
				{
					id: 2,
					name: "mixkit-clear-announce-tones-2861",
					src: require("@/assets/sounds/mixkit-clear-announce-tones-2861.wav"),
				},
				{
					id: 3,
					name: "mixkit-correct-answer-reward-952",
					src: require("@/assets/sounds/mixkit-correct-answer-reward-952.wav"),
				},
				{
					id: 4,
					name: "mixkit-doorbell-single-press-333",
					src: require("@/assets/sounds/mixkit-doorbell-single-press-333.wav"),
				},
				{
					id: 5,
					name: "mixkit-happy-bells-notification-937",
					src: require("@/assets/sounds/mixkit-happy-bells-notification-937.wav"),
				},
				{
					id: 6,
					name: "mixkit-magic-notification-ring-2344",
					src: require("@/assets/sounds/mixkit-magic-notification-ring-2344.wav"),
				},
				{
					id: 7,
					name: "mixkit-musical-alert-notification-2309",
					src: require("@/assets/sounds/mixkit-musical-alert-notification-2309.wav"),
				},
				{
					id: 8,
					name: "mixkit-sci-fi-reject-notification-896",
					src: require("@/assets/sounds/mixkit-sci-fi-reject-notification-896.wav"),
				},
				{
					id: 9,
					name: "notification",
					src: require("@/assets/sounds/notification.mp3"),
				},
				{
					id: 10,
					name: "notification2",
					src: require("@/assets/sounds/notification2.mp3"),
				},
				{
					id: 11,
					name: "notification3",
					src: require("@/assets/sounds/notification3.mp3"),
				},
				{
					id: 12,
					name: "notification4",
					src: require("@/assets/sounds/notification4.mp3"),
				},
				{
					id: 13,
					name: "notification5",
					src: require("@/assets/sounds/notification5.mp3"),
				},
			],
			pickup: false,
			delivery: false,
			maintenance: false,
			lb_enableOrderScheduling: false,
			hideProductInfo: false,
			showAlergens: false,
			lb_show_orders_date_filter: false,
			showProductInfo: false,
			disableZipCodeCheck: false,
			defaultLang: 'de',
			showStandardProductInfo: false,
			standardProductInfo_de: null,
			standardProductInfo_en: null,
			standardProductInfo_fr: null,
			standardProductInfo_ar: null,
			taxes: [],
			tax: null,
			quillOptions: quillOptions,
		};
	},
	computed: {
		languages() {
			return [
				{
					name: this.$t('generic.lang_german'),
					value: 'de',
					flag: "de"
				},
				{
					name: this.$t('generic.lang_english'),
					value: 'en',
					flag: "GB"
				},
				{
					name: this.$t('generic.lang_french'),
					value: 'fr',
					flag: "fr"
				},
				{
					name: this.$t('generic.lang_arabic'),
					value: 'ar',
					flag: "sa"
				}
			]
		}
	},
	methods: {
		async getTaxes() {
			this.axios
				.post(ENDPOINTS.ERP.TAXSETUP.GETALL, this.form)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.taxes = res.data.tax;
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getData() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.OTHERSETTINGS.GET)
				.then((res) => {
					this.lb_deliveryPreparationTime = res.data.formfillData.textFields.lb_deliveryPreparationTime;
					this.lb_pickUpPreparationTime = res.data.formfillData.textFields.lb_pickUpPreparationTime;
					this.pickup = res.data.formfillData.textFields.lb_offersPickUpAtRestaurant;
					this.delivery = res.data.formfillData.textFields.lb_offersDelivery;
					this.maintenance = res.data.formfillData.textFields.lb_maintenanceMode;
					this.maintenance = res.data.formfillData.textFields.lb_maintenanceMode;
					this.hideProductInfo = res.data.formfillData.textFields.hideProductInfo;
					this.showAlergens = res.data.formfillData.textFields.showAlergens;
					this.showProductInfo = res.data.formfillData.textFields.showProductInfo;
					this.showStandardProductInfo = res.data.formfillData.textFields.showStandardProductInfo;
					this.standardProductInfo_de = res.data.formfillData.textFields.standardProductInfo_de;
					this.standardProductInfo_en = res.data.formfillData.textFields.standardProductInfo_en;
					this.standardProductInfo_fr = res.data.formfillData.textFields.standardProductInfo_fr;
					this.standardProductInfo_ar = res.data.formfillData.textFields.standardProductInfo_ar;
					this.tax = this.taxes.find(
						(tax) => tax.id === Number(res.data.formfillData.textFields.lb_deliveryCostsTaxId)
					);
					let sound = this.sounds.find((s) => s.id === res.data.formfillData.textFields.notificationSoundID);

					this.lb_show_orders_date_filter = res.data.formfillData.textFields.lb_show_orders_date_filter;
					this.lb_enableOrderScheduling = res.data.formfillData.textFields.lb_enableOrderScheduling;
					this.notificationSound = sound ? sound : 0;
					this.lb_maxDeliveryTime = Number(res.data.formfillData.textFields.lb_maxDeliveryTime) || 0;
					this.lb_maxDeliveryTime = Number(res.data.formfillData.textFields.lb_maxDeliveryTime) || 0;
					this.defaultLang = res.data.formfillData.textFields.lb_defaultLang || 'de';
					this.disableZipCodeCheck = res.data.formfillData.textFields.lb_disableZipCodeCheck || false;
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						color: "error",
						message: err.message,
					});
				})
				.finally((fin) => {
					this.loading = false;
				});
		},
		update() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.OTHERSETTINGS.UPDATE, {
					lb_deliveryPreparationTime: parseInt(Number(this.lb_deliveryPreparationTime)),
					lb_pickUpPreparationTime: parseInt(Number(this.lb_pickUpPreparationTime)),
					lb_offersPickUpAtRestaurant: parseInt(Number(this.pickup)),
					lb_offersDelivery: parseInt(Number(this.delivery)),
					lb_maintenanceMode: parseInt(Number(this.maintenance)),
					notificationSoundID: this.notificationSound ? this.notificationSound.id : 0,
					hideProductInfo: this.hideProductInfo,
					showAlergens: this.showAlergens,
					showProductInfo: this.showProductInfo,
					showStandardProductInfo: this.showStandardProductInfo,
					standardProductInfo_de: this.standardProductInfo_de,
					standardProductInfo_en: this.standardProductInfo_en,
					standardProductInfo_fr: this.standardProductInfo_fr,
					standardProductInfo_ar: this.standardProductInfo_ar,
					lb_deliveryCostsTaxId: this.tax ? this.tax.id : 0,
					lb_deliveryCostsTaxRate: this.tax ? Number(this.tax.value) : 0,
					lb_show_orders_date_filter: this.lb_show_orders_date_filter,
					lb_enableOrderScheduling: this.lb_enableOrderScheduling,
					lb_maxDeliveryTime: Number(this.lb_maxDeliveryTime),
					lb_defaultLang: this.defaultLang,
					lb_disableZipCodeCheck: this.disableZipCodeCheck,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							color: "success",
							message: this.$t("generic.lang_dataSaved"),
						});
					} else {
						Events.$emit("showSnackbar", {
							color: "error",
							message: res.data.msg || res.data.status,
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						color: "error",
						message: err.message,
					});
				})
				.finally((fin) => {
					this.loading = false;
				});
		},
		playSound() {
			if (this.notificationSound) {
				let audio = new Audio(this.notificationSound.src);
				audio.play();
			} else {
				Events.$emit("showSnackbar", {
					color: "warning",
					message: this.$t("generic.lang_pleaseSelectSoundToPlay"),
				});
			}
		},
	},
	async mounted() {
		await this.getTaxes();
		this.getData();
	},
};
</script>

<style scoped>
.quill-editor {
	height: 300px !important;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
	color: inherit !important;
}
</style>
