<template>
	<v-card :loading="loading">
		<b-tabs content-class="mt-3">
			<b-tab :title="$t('generic.lang_globalInformations')" class="active">
				<v-container>
					<v-form ref="form" v-model="valid">
						<v-row>
							<v-col cols="12" sm="6" md="6" lg="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('localbee.lang_lbRestaurantName')"
									:rules="[rules.required]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									required
									v-model="displayedName"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('localbee.lang_lbRestaurantStreet')"
									:rules="[rules.required]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									required
									v-model="street"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('localbee.lang_lbRestaurantStreetNo')"
									:rules="[rules.required]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									required
									v-model="houseNumber"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('customers.lang_cust_zip')"
									:rules="[rules.required]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									required
									v-model="zipCode"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('settings.lang_city')"
									:rules="[rules.required]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									required
									v-model="city"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('localbee.lang_lbRestaurantEmail')"
									:rules="[rules.required, rules.email]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									required
									v-model="email"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6" xl="6">
								<v-text-field
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('generic.lang_phoneNumber')"
									:rules="[rules.required]"
									@focus="showTouchKeyboard"
									autocomplete="off"
									dense
									outlined
									type="number"
									v-model="phone"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="6" xl="6">
								<v-textarea
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:label="$t('localbee.lang_lbShortDescr')"
									@focus="showTouchKeyboard"
									dense
									outlined
									v-model="shortDesc"
								>
								</v-textarea>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" sm="12" md="12" lg="12">
								<v-subheader>
									<strong>{{ $t("localbee.lang_lbDescr") }}</strong>
								</v-subheader>
								<quill-editor
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:disabled="loading"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-16"
									v-model="fullDesc"
								>
								</quill-editor>
							</v-col>
							<v-col cols="12" sm="12" md="12" lg="12">
								<b-tabs>
									<b-tab active>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="DE" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_imprint") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.german.imprints"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="">
												<country-flag country="GB" />
											</v-btn>
										</template>
										<b-card class="pa-2 transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_imprint") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor_2"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.english.imprints"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="FR" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_imprint") }}</strong>
													</v-subheader>
													<quill-editor
														id="privacy_editor_3"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.french.imprints"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="">
												<country-flag country="SA" />
											</v-btn>
										</template>
										<b-card class="pa-2 transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_imprint") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor_4"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.arabic.imprints"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>
								</b-tabs>
								<v-divider class="ma-0" />
								<br /><br />
								<b-tabs>
									<b-tab active>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="DE" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_privacy_policy") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.german.dataProtection"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="GB" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_privacy_policy") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor_2"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.english.dataProtection"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="FR" />
											</v-btn>
										</template>
										<b-card class="pa-2 transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_privacy_policy") }}</strong>
													</v-subheader>
													<quill-editor
														id="privacy_editor_3"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.french.dataProtection"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="SA" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_privacy_policy") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor_4"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.arabic.dataProtection"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>
								</b-tabs>
								<v-divider class="ma-0" />
								<br /><br />
								<b-tabs>
									<b-tab active>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="DE" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_terms_and_conditions") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.german.agb"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="GB" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_terms_and_conditions") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor_2"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.english.agb"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="FR" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_terms_and_conditions") }}</strong>
													</v-subheader>
													<quill-editor
														id="privacy_editor_3"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.french.agb"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>

									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="SA" />
											</v-btn>
										</template>
										<b-card class="transparent">
											<v-row no-gutters>
												<v-col class="" cols="12">
													<v-subheader
														><strong>{{ $t("generic.lang_terms_and_conditions") }}</strong></v-subheader
													>
													<quill-editor
														id="privacy_editor_4"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:options="quillOptions"
														@focus="showTouchKeyboard"
														output="html"
														class="mb-12"
														v-model="dynamicContent.arabic.agb"
													>
													</quill-editor>
												</v-col>
											</v-row>
										</b-card>
									</b-tab>
								</b-tabs>
							</v-col>
						</v-row>
					</v-form>
					<v-card-actions class="pa-1">
						<v-row>
							<v-col cols="12" align="end">
								<v-btn large color="success" :loading="loading" :disabled="!valid" @click="update">
									{{ $t("generic.lang_save") }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-container>
			</b-tab>
		</b-tabs>
		<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
			<vue-touch-keyboard
				:accept="hideTouchKeyboard"
				:cancel="hideTouchKeyboard"
				:defaultKeySet="touchKeyboard.keySet"
				:input="touchKeyboard.input"
				:layout="touchKeyboard.layout"
				:options="touchKeyboard.options"
				class="internalWidth"
				id="onScreenKeyboard"
				v-if="touchKeyboard.visible"
			/>
		</div>
	</v-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import { Events } from "@/plugins/events";
import { ENDPOINTS } from "@/config";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import CountryFlag from "vue-country-flag";
import validation from "../../../mixins/validation/validation";
import { quillOptions } from "@/plugins/quillSetup.js";

export default {
	name: "GeneralInfoComponent",
	components: { quillEditor, CountryFlag },
	mixins: [mixin, validation],
	data() {
		return {
			ENDPOINTS,
			quillOptions: quillOptions,
			loading: false,
			loading2: false,
			valid: false,
			valid2: false,
			dynamicContent: {
				arabic: {
					agb: "",
					imprints: "",
					dataProtection: "",
				},
				english: {
					agb: "",
					imprints: "",
					dataProtection: "",
				},
				french: {
					agb: "",
					imprints: "",
					dataProtection: "",
				},
				german: {
					agb: "",
					imprints: "",
					dataProtection: "",
				},
			},
			displayedName: null,
			street: null,
			houseNumber: null,
			zipCode: null,
			city: null,
			email: null,
			phone: null,
			shortDesc: null,
			fullDesc: null,
			paymentInfo: null,
			imprintText: null,
			emailFooterText: null,
			globalProductInfo: null,
		};
	},
	computed: {},
	methods: {
		getData() {
			this.loading = true;

			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.GENERALINFO.GET)
				.then((res) => {
					this.displayedName = res.data.formfillData.textFields.lb_restaurantName;
					this.street = res.data.formfillData.textFields.lb_restaurantStreet;
					this.houseNumber = res.data.formfillData.textFields.lb_restaurantStreetNo;
					this.zipCode = res.data.formfillData.textFields.lb_restaurantZip;
					this.city = res.data.formfillData.textFields.lb_restaurantCity;
					this.email = res.data.formfillData.textFields.lb_restaurantEmail;
					this.phone = res.data.formfillData.textFields.lb_restaurantPhoneNo;
					this.shortDesc = res.data.formfillData.textFields.lb_restaurantShortDescription;
					this.fullDesc = res.data.formfillData.textFields.lb_restaurantDescription;
					this.paymentInfo = res.data.formfillData.textFields.lb_restaurantPaymentInfo;
					this.imprintText = res.data.formfillData.textFields.lb_restaurantImprint;
					this.emailFooterText = res.data.formfillData.textFields.lb_restaurantEmailFooterText;
					this.globalProductInfo = res.data.formfillData.textFields.lb_restaurantGlobalProductInfo;
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally((fin) => {
					this.loading = false;
				});
		},
		update() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.loading = true;

			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.GENERALINFO.UPDATE, {
					lb_restaurantName: this.displayedName,
					lb_restaurantStreet: this.street,
					lb_restaurantStreetNo: this.houseNumber,
					lb_restaurantZip: this.zipCode,
					lb_restaurantCity: this.city,
					lb_restaurantEmail: this.email,
					lb_restaurantPhoneNo: this.phone,
					lb_restaurantShortDescription: this.shortDesc,
					lb_restaurantDescription: this.fullDesc,
					lb_restaurantPaymentInfo: this.paymentInfo,
					lb_restaurantImprint: this.imprintText,
					lb_restaurantEmailFooterText: this.emailFooterText,
					lb_restaurantGlobalProductInfo: this.globalProductInfo,
				})
				.then((res) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_updatedSuccessfully"),
						color: "success",
					});
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally((fin) => {
					this.loading = false;
					this.updateFooterContent();
				});
		},
		updateFooterContent() {
			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.DYNAMICCONTENT.UPDATE, {
					agb_ar: this.dynamicContent.arabic.agb,
					dataProtection_ar: this.dynamicContent.arabic.dataProtection,
					imprints_ar: this.dynamicContent.arabic.imprints,
					agb_fr: this.dynamicContent.french.agb,
					dataProtection_fr: this.dynamicContent.french.dataProtection,
					imprints_fr: this.dynamicContent.french.imprints,
					agb_en: this.dynamicContent.english.agb,
					dataProtection_en: this.dynamicContent.english.dataProtection,
					imprints_en: this.dynamicContent.english.imprints,
					agb_de: this.dynamicContent.german.agb,
					dataProtection_de: this.dynamicContent.german.dataProtection,
					imprints_de: this.dynamicContent.german.imprints,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		getDynamicContent() {
			this.loading = true;

			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.DYNAMICCONTENT.GET, {})
				.then((res) => {
					if (res.status === 200) {
						Object.assign(this.dynamicContent, res.data);
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.getData();
		this.getDynamicContent();
	},
};
</script>

<style scoped>
.card-body {
	padding: 2px !important;
}
.quill-editor {
	height: 300px !important;
}
</style>
