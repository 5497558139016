<template>
  <v-card>
    <v-card-title>
      {{$t('generic.lang_preview')}}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-container>
      <v-row no-gutters justify="center" v-if="localbeeWebsite">
        <v-col cols="12" align-self="center">
          <div class="card elevation-0 ma-0 transparent">
            <v-row align="center">
              <v-col cols="12" align="center">
                <h6 class="text-muted pb-2">{{$t("preview.lang_directLinkLocalBee")}}</h6>
                <qr-code
                    :text="'https://'+localbeeWebsite"
                    :size="250"
                    >
                </qr-code>
              </v-col>
              <v-col cols="12" align="center">
                <h6 class="text-muted">{{$t("preview.lang_directLinkNewWindow")}}</h6>
                <v-btn @click="openDomain()" color="info">
                  {{$t("preview.lang_openLocalbee")}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col align="center" cols="12">
                <h6 class="text-muted" @click="copyToClipBoard">
                  {{$t("preview.lang_visit")}}:
                </h6>
              </v-col>
              <v-col align="center" >
                <v-card
                    class="text-center text--primary"
                    filled
                    elevation="0"
                    outlined
                    color="lightgrey"
                >
                  <v-toolbar elevation="0">
                    <v-toolbar-title>
                      {{localbeeWebsite}}
                    </v-toolbar-title>
                    <v-spacer/> 
                    <v-btn @click="copyToClipBoard" color="transparent" elevation="0" class="ma-0">
                      {{ $t('generic.lang_copy') }}<v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </v-toolbar>

                </v-card>

              </v-col>

            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row justify="center" v-if="localbeeWebsite">
        <v-col cols="10" align="center">
          <iframe :src="'https://'+localbeeWebsite" name="iframe" id="iframe" scrolling="yes" frameborder="0" marginheight="0px" marginwidth="0px" height="850" width="100%" style="max-width: 500px">
          </iframe>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from "../../../config";
import VueQRCodeComponent from 'vue-qrcode-component'
export default {
  name: "PreviewComponent",
  components:{
    'qr-code':VueQRCodeComponent
  },
  data(){
    return{
      localbeeWebsite:null,
      loading:null,
    }
  },
  methods:{
    copyToClipBoard(){
      navigator.clipboard.writeText(this.localbeeWebsite);
      Events.$emit("showSnackbar", {
        message: this.localbeeWebsite+"\n" + this.$t('generic.lang_copy2Clipboard'),
        color: "info"
      });
    },
    openDomain() {
      if (this.localbeeWebsite) {
        let domain = this.localbeeWebsite;
        let a = document.createElement('a');
        a.href = 'https://' + domain;
        a.target = '_blank';
        a.click();

        a.remove();
      }
    },
    loadSubdomains() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.GET).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          if(res.data.hasDomains){
            let domain=res.data.domains.find(d=>d.service==='localbee')
            if(domain){
              this.localbeeWebsite=domain.domain;
            }
          }else{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_noDomainHasBeenFound'),
              color: "error"
            });
          }

        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.loadSubdomains();
  }
}
</script>

<style scoped>

</style>