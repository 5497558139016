<template>
  <v-card>
    <v-card-title class="card-title card-header-tab card-header white">

      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
        {{ $t('delivery.lang_selfServiceDesign') }}
      </div>
      <v-spacer/>
    </v-card-title>
    <v-divider class="pa-0 ma-0"/>
    <v-card-text class="ma-0 pa-0">
      <v-container class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <template>
              <div role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" v-b-toggle.accordion1 class="p-1" role="tab">
                    <v-row>
                      <v-col>
                        <div class="pa-5 d-block">
                          {{ $t('erp.lang_warecreate_color') }}
                        </div>
                      </v-col>
                      <v-col align="end" class="pa-5 ">
                        <v-btn color="success" @click="updateColor" :loading="loadingLayout">
                          {{ $t('generic.lang_save') }}
                        </v-btn>
                      </v-col>
                    </v-row>

                  </b-card-header>
                  <b-collapse id="accordion1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row>

                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('tablebee.lang_primary_color') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker
                                      mode="hexa"
                                      v-model="color.primary"
                                      class="ma-0"
                                      width="100%"
                                      hide-canvas
                                      show-swatches
                                      swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('tablebee.lang_primary_color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('tablebee.lang_secondary_color') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="color.secondary"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('tablebee.lang_secondary_color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('generic.lang_backgroundcolor') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="color.background"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_backgroundcolor') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('tablebee.lang_tertiary_color') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="color.tertiary"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('tablebee.lang_tertiary_color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('tablebee.lang_accent_color') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="color.accent"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('tablebee.lang_accent_color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{$t('generic.lang_productCardBackground')}}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="color.product_card"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{$t('generic.lang_productCardBackground')}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" v-b-toggle.accordion2 class="p-1" role="tab">
                    <v-row>
                      <v-col>
                        <div class="pa-5 d-block">
                          {{ $t('generic.lang_text') }}
                        </div>
                      </v-col>
                      <v-col align="end" class="pa-5 ">
                        <v-btn color="success" @click="updateText" :loading="loadingLayout">
                          {{ $t('generic.lang_save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </b-card-header>
                  <b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row>
                        <v-col cols="12">
                          <v-switch :label="$t('tablebee.lang_hide_product_details')" v-model="texts.hideProductDetails" :value="true"/>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                              v-model="texts.fontFamily"
                              :items="data.available_fonts"
                              :label="$t('tablebee.lang_font_family')"
                              outlined
                              dense
                          >
                            <template v-slot:item="{ item }">
                              <span :style="{fontFamily : item}">{{ item }}</span>

                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    <H1>{{ $t('generic.lang_headline1') }}</H1>
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.headLine1"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_headline1Color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    <H2>{{ $t('generic.lang_headline2') }}</H2>
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.headLine2"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_headline2Color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    <h3>{{ $t('generic.lang_headline3') }}</h3>
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.headLine3"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_headline3Color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    <h4>{{ $t('generic.lang_headline4') }}</h4>
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.headLine4"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_headline4Color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    <h5>{{ $t('generic.lang_headline5') }}</h5>
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.headLine5"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_headline5Color') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    <H6>{{ $t('generic.lang_headline6') }}</H6>
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.headLine6"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_headLineColor') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('generic.lang_subheader') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.subHeader"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_subHeaderColor') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('generic.lang_product_name') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.productName"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_productNameColor') }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                                <div class="pa-0 ma-0 dropdown-menu-header">

                                  <div class="card-header bg-info text-white">
                                    {{ $t('generic.lang_product_description') }}
                                  </div>
                                </div>
                                <div class="card-body pa-0">
                                  <v-color-picker mode="hexa"
                                                  v-model="texts.productDescription"
                                                  class="ma-0"
                                                  width="100%"
                                                  hide-canvas
                                                  show-swatches
                                                  swatches-max-height="100"
                                  ></v-color-picker>
                                </div>
                              </div>
                            </template>
                            <span>{{ $t('generic.lang_productDescriptionColor') }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <!-- start images -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" v-b-toggle.accordion3 class="p-1" role="tab">
                    <v-row>
                      <v-col>
                        <div class="pa-5 d-block">
                          {{ $t('tablebee.lang_images') }}
                        </div>
                      </v-col>
                    </v-row>
                  </b-card-header>
                  <b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">
                              <div class="card-header bg-info text-white">
                                {{ $t('tablebee.lang_welcome_page_logo') }}
                              </div>
                            </div>
                            <div class="card-body">
                              <image-cropper ref="welcomePageLogo"
                                             v-model="image.welcomePageLogo"/>
                            </div>
                            <div class="d-block text-right card-footer pa-0">
                              <v-btn :disabled="loaders.logo" :loading="loaders.welcomePageLogo"
                                     @click="upload(1)" block class="btn bg-muted text-dark mx-auto mt-0 mb-0"
                                     large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{ this.$t('generic.lang_hochladen') }}
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">
                              <div class="card-header bg-info text-white">
                                {{ 'Fab icon' }}
                              </div>
                            </div>
                            <div class="card-body">
                              <image-cropper ref="fabIcon"
                                             v-model="image.fabIcon"/>
                            </div>
                            <div class="d-block text-right card-footer pa-0">
                              <v-btn :disabled="loaders.logo" :loading="loaders.fabIcon"
                                     @click="upload(2)" block class="btn bg-muted text-dark mx-auto mt-0 mb-0"
                                     large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{ this.$t('generic.lang_hochladen') }}
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6" md="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">
                              <div class="card-header bg-info text-white">
                                {{ 'Logo' }}
                              </div>
                            </div>
                            <div class="card-body">
                              <image-cropper ref="logo"
                                             v-model="image.logo"/>
                            </div>
                            <div class="d-block text-right card-footer pa-0">
                              <v-btn :disabled="loaders.logo" :loading="loaders.logo"
                                     @click="upload(3)" block class="btn bg-muted text-dark mx-auto mt-0 mb-0"
                                     large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{ this.$t('generic.lang_hochladen') }}
                              </v-btn>
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="6" md="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">
                              <div class="card-header bg-info text-white">
                                {{ $t('tablebee.lang_default_product_image') }}
                              </div>
                            </div>
                            <div class="card-body">
                              <image-cropper ref="product_image"
                                             v-model="image.product_image"/>
                            </div>
                            <div class="d-block text-right card-footer pa-0">
                              <v-btn :disabled="loaders.product_image" :loading="loaders.product_image"
                                     @click="upload(4)" block class="btn bg-muted text-dark mx-auto mt-0 mb-0"
                                     large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{ this.$t('generic.lang_hochladen') }}
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- end images -->

                <!-- start of messages -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" v-b-toggle.accordion4 class="p-1" role="tab">
                    <v-row>
                      <v-col>
                        <div class="pa-5 d-block">
                          {{ $t('generic.lang_gui_messages') }}
                        </div>
                      </v-col>
                      <v-col align="end" class="pa-5 ">
                        <v-btn color="success" @click="updateMessages" :loading="loadingLayout">
                          {{ $t('generic.lang_save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </b-card-header>
                  <b-collapse id="accordion4" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-tabs v-if="this.isTranslationEnabled">
                        <b-tab active>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="DE"/>
                            </v-btn>
                          </template>

                          <v-row class="pa-0" no-gutters>
                            <v-col class="" cols="12">
                              <v-subheader><strong>Nachricht auf der Willkommensseite</strong></v-subheader>
                              <quill-editor id="editor1" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.startPage">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5" cols="12">
                              <v-subheader><strong>Nach der Nachricht auf der Namensseite fragen </strong></v-subheader>
                              <quill-editor id="editor2" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.nameMsg">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5">
                              <v-subheader><strong>Nachrichtenmenü </strong></v-subheader>

                              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" outlined
                                            dense
                                            :label="$t('delivery.lang_menuMessages')" value="14" v-model="messages.menuMsg"/>
                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="GB"/>
                            </v-btn>
                          </template>

                          <v-row class="pa-0" no-gutters>
                            <v-col class="" cols="12">
                              <v-subheader><strong>Welcome Page Message</strong></v-subheader>
                              <quill-editor id="editor3" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.startPage_en">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5" cols="12">
                              <v-subheader><strong>Ask For Name Page Message </strong></v-subheader>
                              <quill-editor id="editor4" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.nameMsg_en">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5">
                              <v-subheader><strong>Menu Message </strong></v-subheader>

                              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" outlined
                                            dense
                                            :label="$t('delivery.lang_menuMessages')" value="14" v-model="messages.menuMsg_en"/>
                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="FR"/>
                            </v-btn>
                          </template>


                          <v-row class="pa-0" no-gutters>
                            <v-col class="" cols="12">
                              <v-subheader><strong>Message de la page de bienvenue</strong></v-subheader>
                              <quill-editor id="editor5" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.startPage_fr">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5" cols="12">
                              <v-subheader><strong>Le message du page de nom </strong></v-subheader>
                              <quill-editor id="editor6" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.nameMsg_fr">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5">
                              <v-subheader><strong>Menu Message </strong></v-subheader>

                              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" outlined
                                            dense
                                            :label="$t('delivery.lang_menuMessages')" value="14" v-model="messages.menuMsg_fr"/>
                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="SA"/>
                            </v-btn>
                          </template>


                          <v-row class="pa-0 text-right" no-gutters>
                            <v-col class="" cols="12">
                              <v-subheader><strong>رسالة صفحة الترحيب</strong></v-subheader>
                              <quill-editor id="editor7" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.startPage_ar">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5" cols="12">
                              <v-subheader><strong>رسالة صفحة إدخال الاسم </strong></v-subheader>
                              <quill-editor id="editor8" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="messages.nameMsg_ar">

                              </quill-editor>
                            </v-col>
                            <v-col class="pt-5">
                              <v-subheader><strong>رسالة قائمة المنتجات </strong></v-subheader>

                              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" outlined
                                            dense
                                            :label="$t('delivery.lang_menuMessages')" value="14" v-model="messages.menuMsg_ar"/>
                            </v-col>
                          </v-row>
                        </b-tab>
                      </b-tabs>

                      <v-row v-else class="pa-0" no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader><strong>{{ $t('generic.lang_messageOnTheWelcomePage') }}</strong></v-subheader>
                          <quill-editor id="editor1" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                        @focus="showTouchKeyboard"
                                        output="html" class="mb-4" v-model="messages.startPage">

                          </quill-editor>
                        </v-col>
                        <v-col class="pt-5" cols="12">
                          <v-subheader><strong>{{ $t('generic.lang_askForTheMessageOnTheNamePage') }} </strong></v-subheader>
                          <quill-editor id="editor2" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                        @focus="showTouchKeyboard"
                                        output="html" class="mb-4" v-model="messages.nameMsg">

                          </quill-editor>
                        </v-col>
                        <v-col class="pt-5">
                          <v-subheader><strong>{{ $t('generic.lang_messagesMenu') }} </strong></v-subheader>

                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" outlined
                                        dense
                                        :label="$t('delivery.lang_menuMessages')" value="14" v-model="messages.menuMsg"/>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- end of messages -->

                <!-- start of privacy -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" v-b-toggle.accordion5 class="p-1" role="tab">
                    <v-row>
                      <v-col>
                        <div class="pa-5 d-block">
                          {{ $t('tablebee.lang_privacy_and_allergens') }}
                        </div>
                      </v-col>
                      <v-col align="end" class="pa-5 ">
                        <v-btn color="success" @click="updatePrivacy" :loading="loadingLayout">
                          {{ $t('generic.lang_save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </b-card-header>
                  <b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-tabs v-if="this.isTranslationEnabled">
                        <b-tab active>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="DE"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>TableBee-Datenschutz und -Bedingungen</strong></v-subheader>
                              <quill-editor id="privacy_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.privacy">

                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="GB"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>TableBee privacy and conditions</strong></v-subheader>
                              <quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.privacy_en">

                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="FR"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>Confidentialité et conditions de TableBee</strong></v-subheader>
                              <quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.privacy_fr">

                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="SA"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>الشروط والخصوصية</strong></v-subheader>
                              <quill-editor id="privacy_editor_4" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.privacy_ar">

                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>
                      </b-tabs>
                      <v-row v-else>
                        <v-col class="" cols="12">
                          <v-subheader><strong>{{ $t('generic.lang_tablebeePrivacyPolicyAndTerms') }}</strong></v-subheader>
                          <quill-editor id="privacy_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        :options="quillOptions"
                                        @focus="showTouchKeyboard"
                                        output="html" class="mb-4" v-model="conditions.privacy">

                          </quill-editor>

                        </v-col>
                      </v-row>

                      <!-- allergens -->
                      <br/><br/>
                      <b-tabs class="border-top pt-3" v-if="this.isTranslationEnabled">
                        <b-tab active>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="DE"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>{{ $t('erp.lang_Allergene') }}</strong></v-subheader>
                              <quill-editor id="allergens_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.allergens">
                              </quill-editor>
                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="GB"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>{{ $t('erp.lang_Allergene') }}</strong></v-subheader>
                              <quill-editor id="allergens_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.allergens_en">

                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="FR"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>{{ $t('erp.lang_Allergene') }}</strong></v-subheader>
                              <quill-editor id="allergens_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.allergens_fr">
                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <v-btn text class="ma-0">
                              <country-flag country="SA"/>
                            </v-btn>
                          </template>
                          <v-row>
                            <v-col class="" cols="12">
                              <v-subheader><strong>{{ $t('erp.lang_Allergene') }}</strong></v-subheader>
                              <quill-editor id="privacy_editor_8" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :options="quillOptions"
                                            @focus="showTouchKeyboard"
                                            output="html" class="mb-4" v-model="conditions.allergens_ar">

                              </quill-editor>

                            </v-col>
                          </v-row>
                        </b-tab>
                      </b-tabs>

                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- start of privacy -->
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-col cols="12" align="end">
        <v-btn color="primary">
          {{ $t('generic.lang_restDefaultConfig') }}
        </v-btn>
      </v-col>

    </v-card-actions>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import ImageCropper from "../../common/imagecropper";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from '@/config';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';
import CountryFlag from "vue-country-flag";
import {createNamespacedHelpers} from "vuex";


export default {
  name: "LogosComponent",
  components: {quillEditor, ImageCropper, CountryFlag},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      selected_language_tab: "de",
      loading: false,
      loadingLayout: false,
      data: {
        available_fonts: [
          'Arial',
          'Verdana',
          'Helvetica',
          'Tahoma',
          'Trebuchet MS',
          'Times New Roman',
          'Georgia',
          'Garamond',
          "Pacifico",
          "Dancing Script",
          "Shadows Into Light",
          "Lobster",
          "Anton",
          "Indie Flower",
          "Charmonman",
          "Kodchasan",
          "Notable",
          "Mali",
          "Srisakdi",
          "Slabo 27px"
        ]
      },
      conditions: {
        privacy: "",
        privacy_en: "",
        privacy_fr: "",
        privacy_ar: "",
        allergens: "",
        allergens_en: "",
        allergens_fr: "",
        allergens_ar: ""
      },
      texts: {
        hideProductDetails: false,
        fontFamily: null,
        headLine1: null,
        headLine2: null,
        headLine3: null,
        headLine4: null,
        headLine5: null,
        headLine6: null,
        subHeader: null,
        productName: null,
        productDescription: null
      },
      color: {
        primary: null,
        secondary: null,
        background: null,
        tertiary: null,
        accent: null,
        product_card: null,
      },
      image: {
        welcomePageLogo: '',
        fabIcon: '',
        logo: '',
        product_image: ''
      },
      messages: {
        startPage: '',
        nameMsg: '',
        menuMsg: '',
        //en
        startPage_en: '',
        nameMsg_en: '',
        menuMsg_en: '',
        //fr
        startPage_fr: '',
        nameMsg_fr: '',
        menuMsg_fr: '',
        //ar
        startPage_ar: '',
        nameMsg_ar: '',
        menuMsg_ar: '',
      },
      loaders: {
        welcomePageLogo: false,
        fabIcon: false,
        logo: false,
        product_image: false
      },
      quillOptions: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
      layout: {},

    }
  },
  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    }
  },
  methods: {
    getMessages() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.MESSAGES.GET)
          .then(res => {
            if (res.status === 200) {
              this.messages = res.data.messages
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    getText() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.TEXT.GET)
          .then(res => {
            if (res.status === 200) {
              this.texts = res.data.texts
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    getColor() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.COLOR.GET)
          .then(res => {
            if (res.status === 200) {
              this.color = res.data.color
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    getLogos() {
      this.loading = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.LOGOS.GET).then((res) => {
        if (res.status === 200) {
          this.image.welcomePageLogo = res.data.image.welcomePageLogo === 'data:image/;base64,' ? null : res.data.image.welcomePageLogo;
          this.image.logo = res.data.image.logo === 'data:image/;base64,' ? null : res.data.image.logo;
          this.image.fabIcon = res.data.image.fabIcon === 'data:image/;base64,' ? null : res.data.image.fabIcon;
          this.image.product_image = res.data.image.default_product_image === 'data:image/;base64,' ? null : res.data.image.default_product_image;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    updateColor() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.COLOR.UPDATE, {
        color: this.color
      }).then(res => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    updateText() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.TEXT.UPDATE, {
        texts: this.texts
      }).then(res => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    updateMessages() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.MESSAGES.UPDATE, {
        messages: this.messages
      }).then(res => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    updatePrivacy() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.CONDITIONS.UPDATE, {
        conditions: this.conditions
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },

    getConditions() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.CONDITIONS.GET)
          .then(res => {
            if (res.status === 200) {
              this.conditions = res.data.conditions
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    upload(type) {
      let logoType = '';
      switch (type) {
        case 1:
          logoType = 'welcomePageLogo';
          break;
        case 2:
          logoType = 'fabIcon';
          break;
        case 3:
          logoType = 'logo';
          break;
        case 4:
          logoType = 'product_image';
          break;
      }

      this.$refs[logoType].cropImage();
      this.loaders[logoType] = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.LOGOS.UPDATE, {
        logoUpload: this.image[logoType],
        typeOfLogo: type,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.status,
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loaders[logoType] = false;
      })
    },

  },
  mounted() {
    this.getMessages();
    this.getText();
    this.getColor();
    this.getLogos();
    this.getConditions();
  },
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}

#editor1, #editor2, #editor3, #editor4, #editor5, #editor6, #editor7 {
  height: 140px;
  width: 100%;
}

#privacy_editor, #privacy_editor_2, #privacy_editor_3, #privacy_editor_4, #privacy_editor_8 , #allergens_editor, #allergens_editor_2, #allergens_editor_3 {
  height: 160px;
}
</style>
