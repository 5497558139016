<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('settings.lang_nav_deliverysettings')"
                :subheading="$t('settings.lang_nav_deliverysettings')"
                :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <DeliverySettingsComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle";
import DeliverySettingsComponent from "@/components/delivery/DeliverySettingsComponent";


export default {
  name: "DeliverySettings",
  components: {
    DeliverySettingsComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-settings icon-gradient bg-tempting-azure',
  })
}
</script>
