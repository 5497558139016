<template>
  <v-card class="pa-0 text-right" elevation="0" tile>
    <v-card-title class="pa-2">
      {{$t('delivery.lang_orderStatus')}}
      <v-spacer/>
      <v-btn class="ma-0" color="info" @click="$router.push({name:'pos.delivery.settings.orderstatus.create'})">
        {{$t('delivery.lang_orderStatusAdd')}}
      </v-btn>
    </v-card-title>
    <v-divider class="ma-0"/>
    <Datatable ref="orderStatusDatatable"
               :api-endpoint="ENDPOINTS.DATATABLES.DELIVERY.DELIVERYSTATUSES"
               :datatable-headers="datatableHeaders"
               no-excel-export
               show-edit-buttons
               show-delete-buttons
               permission-delete
               permission-edit
               @editEntry="entryEdit"
               @deleteEntry="entryDelete"
               @deleteEntries="entriesDelete"
    />
  </v-card>
</template>

<script>
import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";


export default {
  name: "OrderStatusesComponent",
  components: {Datatable},
  data(){
    return{
      ENDPOINTS,
      id:null,
      datatableHeaders:[
        {
          text:'ID',
          value:'id',
          width: 80,
          hide:true,
        },
        {
          text: this.$t('generic.lang_name'),
          value:'name',
        },
        {
          text:'Color',
          value:'color',
          hide:true,
        },
        {
          text:'Icon',
          value:'icon',
          hide:true,
        },
        {
          text:this.$t('generic.lang_duration'),
          value:'Duration',
        },
      ]
    }
  },
  methods:{
    entryEdit(entry){
      this.$router.push({name:'pos.delivery.settings.orderstatus.edit',params:{id:parseInt(entry.id)}});
    },
    entryDelete(entry){
      this.id=entry.id;
      this.deleteData();
    },
    entriesDelete(entries){
      this.deleteData(entries)
    },
    deleteData(idsToDelete = []){
      let self = this;
      this.$swal({
        title: this.$t('delivery.lang_orderStatusDelete'),
        text: this.$t('delivery.lang_orderStatusDeleteMsg'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYSTATUS.DELETE, {
            ids: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_ActionSuccessful'),
                color: "success"
              });
              self.$refs.orderStatusDatatable.getDataFromApi();
              self.$refs.orderStatusDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    }
  },

}
</script>

<style scoped>

</style>