<template>
  <v-container class="pa-0" fluid>

    <v-row class="pa-0 ma-0">

      <v-dialog scrollable v-model="dialog" fullscreen>
        <v-card>
          <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-1">
            <span>{{ editedItem.tmplName }} </span>
            <v-spacer/>
            <v-btn @click="close" class="ma-0" text color="error">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-row class="mx-0">
              <v-col cols="12">
                <v-expansion-panels focusable>
                  <v-expansion-panel
                      class="pa-0 ma-0"
                  >
                    <v-expansion-panel-header class="px-3 py-2">
                      <span>{{ $t('delivery.lang_individual_invoice_variables') }}</span>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0 pt-2">
                      <v-row>
                        <v-col cols="12">
                          <b-form-input  :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        :placeholder="$t('datatables.lang_search')" v-model="search"/>
                        </v-col>
                        <v-col cols="12">
                          <v-row class="pa-0 ma-0">
                            <v-col
                                v-show="((editedItem.id > 2  && !elt.includes('password')) || (editedItem.id > 0))"
                                :key="i" class="pt-0 pb-0"
                                cols="12" md="6" sm="12"
                                v-for="(elt , i) in this.variations.filter((v) => v.toLocaleLowerCase().includes(search.toLocaleLowerCase())).filter(val => {
                                  if(parseInt(editedItem.id) > 2 && !val.includes('password')) return val;
                                  else if(parseInt(editedItem.id) <= 2 ) return val;
                                })">
                              <v-text-field :id="'input_' + i" :value="elt"
                                            :background-color="$vuetify.theme.dark? 'dark-bg' : 'grey lighten-3'" dense outlined
                                            readonly>
                                <template v-slot:append>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn @click="copyText('input_' + i , elt)" @mouseout="out" icon v-bind="attrs"
                                             v-on="on">
                                        <v-icon>content_copy</v-icon>
                                      </v-btn>
                                    </template>
                                    {{ toolpitMsg }}
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col class="pa-0 ma-0" cols="12">
                <v-form lazy-validation ref="form">
                  <v-row class="pa-0 ma-0">
                    <v-col class="pt-0 mt-0" cols="12">
                      <quill-editor id="editor" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                    :rules="[v => !!v]"
                                    @focus="showTouchKeyboard"
                                    output="html" v-model="editedItem.text"></quill-editor>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pt-1 pb-1">
            <v-spacer/>
            <v-btn @click="close" class="bg-danger text-light mx-0">
              {{ this.$t('generic.lang_cancel') }}
            </v-btn>
            <v-btn :disabled="this.loading" :loading="this.loading" @click="saveTamplate"
                   class="bg-primary text-light mx-1">
              {{ this.$t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-col class="mt-0 pt-0" cols="12">
        <v-row>
          <v-col class="pa-0 ma-0" cols="12">
            <v-data-table
                :items="templates"
                :headers="headers"
                :loading="loading"
            >
              <template v-slot:item.crud="{item}">
                <v-btn fab x-small depressed @click="entryEdit(item)" color="primary">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";

export default {
  name: "EmailTemplatesComponent",
  components: {quillEditor},
  mixins: [mixin],
  data(){
    return {
      editedItem: {
        id:0,
        tmplName: "",
        text: "",
      },
      default: {
        id:0,
        tmplName: "",
        text: "",
      },
      templates: [
        {
          id: 19,
          tmplName: this.$t('generic.lang_resetPassword'),
          text: '',
        },
        {
          id: 20,
          tmplName: this.$t('generic.lang_neues_passwort'),
          text: '',
        },
        {
          id: 21,
          tmplName: this.$t('generic.lang_welcome'),
          text: '',
        },
        {
          id: 22,
          tmplName: this.$t('delivery.lang_newOrders'),
          text: '',
        },
        {
          id: 23,
          tmplName: this.$t('emailTmp.lang_GuthabenAufladen'),
          text: '',
        },
        {
          id: 24,
          tmplName: this.$t('emailTmp.lang_NeueMenue'),
          text: '',
        },
        {
          id: 25,
          tmplName: this.$t('generic.lang_yourFavoriteDish'),
          text: '',
        },
        {
          id: 26,
          tmplName: this.$t('emailTmp.lang_infoSheet'),
          text: '',
        },
        {
          id: 27,
          tmplName: this.$t('emailTmp.lang_allergenProfil'),
          text: '',
        },
      ],
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
      edit: false,
      loading: false,
      ENDPOINTS,
      search: "",
      dialog: false,
      toolpitMsg: this.$t('generic.lang_CopyToClipboard'),
    }
  },

  methods: {
    saveTamplate() {
      if (!this.$refs.form.validate()) return;
      if (this.editedItem.text === "") {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_pleaseSetYourTemplateText'),
          color: "error"
        });
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.EMAIL.TEMPLATES.UPDATE, {
        id: this.editedItem.id,
        text: this.editedItem.text,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          // update dataTable
          this.getAllTemplates();
          this.close();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    getAllTemplates() {
      this.loading = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.EMAIL.TEMPLATES.GETALL)
          .then((res) => {
            if(res.data && res.data.length>0){
              this.templates = res.data
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    out() {
      this.toolpitMsg = this.$t('generic.lang_CopyToClipboard')
    },
    copyText(id, elt) {
      elt = elt.substring(elt.search('{{'), elt.search('}}') + 2);
      //console.log(id)
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.toolpitMsg = this.$t('generic.lang_copied') + ": " + elt;
      this.editedItem.text += " " + elt;
    },
    entryEdit(entry) {
      this.editedItem = Object.assign({}, entry);
      this.edit = true;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.default);
      this.$refs.form.reset();
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_template'),
          value: 'tmplName',
        },
        {
          text: '',
          value: 'crud',
          width: 100,
          align: 'center',
          sortable: false,
        },
      ]
    },
    variations() {
      return [
        this.$t('customers.lang_cust_prename') + "  - {{Prename}}",
        this.$t('customers.lang_cust_name') + "  - {{Lastname}}",
        this.$t('settings.lang_companyName') + "  - {{Company}}",
        this.$t('generic.lang_address') + "  - {{Adress}}",
        this.$t('settings.lang_user_password') + "  - {{password}}",
        this.$t('customers.lang_cust_zip') + "  - {{Zip}}",
        this.$t('settings.lang_city') + "  - {{City}}",
        this.$t('generic.lang_country') + "  - {{Country}}",
        this.$t('settings.lang_accountOwner') + "  - {{KontoInhaber}}",
        "IBAN  - {{IBAN}}",
        "BIC  - {{BIC}}",
        this.$t('settings.lang_bankName') + "  - {{Bankname}}",
        this.$t('accounting.lang_MandateReference') + "  - {{Manadatsreferen}}",
        this.$t('accounting.lang_CreditorID') + "  - {{GlaeubigerID}}",
        this.$t('settings.lang_contactFirstName') + "  - {{contactPrename}}",
        this.$t('settings.lang_contactLastName') + "  - {{contactLastname}}",
        this.$t('settings.lang_contactAdress') + "  - {{contactAdress}}",
        this.$t('settings.lang_contactZipCode') + "  - {{contactZip}}",
        this.$t('settings.lang_contactCity') + "  - {{contactCity}}",
        this.$t('settings.lang_contactCountry') + "  - {{contactCountry}}",
        this.$t('settings.lang_deliveryPrenam') + "  - {{deliveryPrenam}}",
        this.$t('settings.lang_deliveryLastname') + "  - {deliveryLastname}",
        this.$t('settings.lang_deliveryAddress') + "  - {{deliveryAdress}}",
        this.$t('settings.lang_deliveryZip') + "  - {{deliveryZip}}",
        this.$t('settings.lang_deliveryCity') + "  - {{deliveryCity}}",
        this.$t('settings.lang_deliveryCountry') + "  - {{deliveryCountry}}",
        this.$t('settings.lang_billingPrename') + "  - {{billingPrename}}",
        this.$t('settings.lang_billingLastname') + "  - {{billingLastname}}",
        this.$t('settings.lang_billingAdress') + "  - {{billingAdress}}",
        this.$t('settings.lang_billingZip') + "  - {{billingZip}}",
        this.$t('settings.lang_billingZip') + "  - {{billingZip}}",
        this.$t('settings.lang_billingCity') + "  - {{billingCity}}",
        this.$t('settings.lang_billingCountry') + "  - {{billingCountry}}",
        this.$t('settings.lang_billingPaymentDeadline') + "  - {{billingPaymentDeadline}}",
        this.$t('settings.lang_billingDatePaymentDeadline') + "  - {{billingDatePaymentDeadline}}"
      ]
    },
  },
  mounted() {
    this.getAllTemplates()
  }
}
</script>

<style scoped>
v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
  border: none !important;
}

#editor {
  height: 180px;
  width: 100%;
}
</style>
