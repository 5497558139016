<template>
      <v-card class="pa-0 text-right" elevation="0" tile>
        <v-card-title class="pa-2">
          {{ $t('generic.lang_deliveryArea') }}
          <v-spacer/>
          <v-btn class="ma-0" color="info" @click="$router.push({name:'pos.delivery.settings.deliveryarea.create'})">
            {{$t('generic.lang_addDeliveryArea')}}
          </v-btn>
        </v-card-title>
        <v-divider class="ma-0"/>
        <Datatable ref="deliveryAreasDatatable"
                   :api-endpoint="ENDPOINTS.DATATABLES.DELIVERY.DELIVERYAREA"
                   :datatable-headers="datatableHeaders"
                   no-excel-export
                   show-edit-buttons
                   show-delete-buttons
                   permission-delete
                   permission-edit
                   @editEntry="entryEdit"
                   @deleteEntry="entryDelete"
                   @deleteEntries="entriesDelete"
        >
          <template v-slot:item.minOrder="{item}">
            {{ item.minOrder | currency }}
          </template>
          <template v-slot:item.cost="{item}">
            {{ item.cost | currency }}
          </template>
        </Datatable>
      </v-card>
</template>

<script>
import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";


export default {
  name: "DeliveryAreasComponent",
  components: {Datatable},
  data(){
    return{
      ENDPOINTS,
      id:null,
    }
  },
  computed:{
    datatableHeaders() {
      return [
        {
          text: 'ID',
          value: 'id',
          width: 80,
          hide: true,
        },
        {
          text: this.$t('generic.lang_areaName'),
          value: 'areaName',
        },
        {
          text: this.$t('settings.lang_city'),
          value: 'city',
        },
        {
          text: this.$t('generic.lang_zip'),
          value: 'zip',
        },
        {
          text: this.$t('erp.lang_deliveryMinimumOrder'),
          value: 'minOrder',
        },
        {
          text: this.$t('erp.lang_Delivery_DeliverySurcharge'),
          value: 'cost',
        },
      ]
    }
  },
  methods:{
    entryEdit(entry){
      this.$router.push({name:'pos.delivery.settings.deliveryarea.edit',params:{id:parseInt(entry.id)}});
    },
    entryDelete(entry){

      this.id=entry.id;
      this.deleteData();
    },
    entriesDelete(entries){
      this.deleteData(entries)
    },
    deleteData(idsToDelete = []){
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_delivery_deleteDeliveryArea'),
        text: this.$t('generic.lang_delivery_deleteDeliveryAreaBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.DELETE, {
            areaId: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_deletedSuccessfully'),
                color: "success"
              });
              self.$refs.deliveryAreasDatatable.getDataFromApi();
              self.$refs.deliveryAreasDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    }
  },

}
</script>

<style scoped>

</style>