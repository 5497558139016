<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0" cols="12" md="3" sm="12">
        <v-row dense>
          <v-col class="pb-0" cols="12" >
            <div role="tablist">
              <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-1"  no-body>
                <b-card-header class="p-0" header-tag="header" role="tab">
                  <v-list-item @click="globalSettings =! globalSettings" class="ma-0"
                               v-b-toggle.localbee-settings>
                    <v-list-item-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.globalSettings? 'primary--text text-wrap' : 'text-wrap']">
                      {{ $t('generic.lang_localBeeInterface') }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon v-if="!this.globalSettings">keyboard_arrow_down</v-icon>
                      <v-icon v-if="this.globalSettings">keyboard_arrow_up</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </b-card-header>
                <b-collapse :visible="this.globalSettings" accordion="localbee-settings" id="localbee-settings"
                            role="tabpanel">
                  <b-card-body class="pa-0">
                    <b-list-group flush>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'generalInfo'? 'primary--text' : '']"
                                         @click="handleClick('generalInfo')"
                                         class="items">
                        {{ $t('generic.lang_globalInformations') }}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'times'? 'primary--text' : '']"
                                         @click="handleClick('times')"
                                         class="items">
                        {{$t('generic.lang_deliverHours')}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'design'? 'primary--text' : '']"
                                         @click="handleClick('design')"
                                         class="items">
                        {{$t('delivery.lang_localbeeDesign')}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'payment'? 'primary--text' : '']"
                                         @click="handleClick('payment')"
                                         class="items">
                        {{$t('generic.lang_zahlungsoptionen')}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'productGroups'? 'primary--text' : '']"
                                         @click="handleClick('productGroups')"
                                         class="items">
                        {{ $t('erp.lang_nav_waregroups') }}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'products'? 'primary--text' : '']"
                                         @click="handleClick('products')"
                                         class="items">
                        {{$t('erp.lang_item')}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'emailTemplates'? 'primary--text' : '']"
                                         @click="handleClick('emailTemplates')"
                                         class="items">
                        {{$t("generic.lang_emailTemplates")}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'autoPrint'? 'primary--text' : '']"
                                         @click="handleClick('autoPrint')"
                                         class="items">
                        {{  this.$t('settings.lang_printer')+' '+this.$t('settings.lang_settings') }}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'settings'? 'primary--text' : '']"
                                         @click="handleClick('settings')"
                                         class="items">
                        {{$t('settings.lang_settings')}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'preview'? 'primary--text' : '']"
                                         @click="handleClick('preview')"
                                         class="items">
                        {{$t('generic.lang_preview')}}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'help'? 'primary--text' : '']"
                                         @click="handleClick('help')"
                                         class="items">
                        {{$t('generic.lang_nav_help')}}
                      </b-list-group-item>
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </v-col>
          <v-col class="pb-0" cols="12">
            <div role="tablist">
              <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-1" no-body>
                <b-card-header class="p-0" header-tag="header" role="tab">
                  <v-list-item @click="globalSettings =! globalSettings" class="ma-0"
                               v-b-toggle.delivery-settings>
                    <v-list-item-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.globalSettings? 'primary--text text-wrap' : 'text-wrap']">
                      {{ $t('settings.lang_nav_deliverysettings') }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon v-if="!this.globalSettings">keyboard_arrow_down</v-icon>
                      <v-icon v-if="this.globalSettings">keyboard_arrow_up</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </b-card-header>
                <b-collapse :visible="this.globalSettings" accordion="delivery-settings" id="delivery-settings"
                            role="tabpanel">
                  <b-card-body class="pa-0">
                    <b-list-group flush>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'deliveryArea'? 'primary--text' : '']"
                                         @click="handleClick('deliveryArea')"
                                         class="items">
                        {{ $t('generic.lang_deliveryArea') }}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'callCenter'? 'primary--text' : '']"
                                         @click="handleClick('callCenter')"
                                         class="items">
                        {{ $t('generic.lang_callCenter') }}
                      </b-list-group-item>
                      <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'status'? 'primary--text' : '']"
                                         @click="handleClick('status')"
                                         class="items">
                        {{ $t('delivery.lang_orderStatus') }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="9" sm="12">

        <div v-if="currentTab === 'generalInfo'">
          <GeneralInfoComponent/>
        </div>

        <div v-if="currentTab === 'times'">
          <DeliveryTimesComponent/>
        </div>

        <div v-if="currentTab === 'design'">
          <DesignComponent/>
        </div>

        <div v-if="currentTab === 'payment'">
          <PaymentOptionsComponent/>
        </div>

        <div v-if="currentTab === 'productGroups'">
          <ProductGroupsComponent/>
        </div>

        <div v-if="currentTab === 'products'">
          <ProductsComponent/>
        </div>

        <div v-if="currentTab === 'emailTemplates'">
          <EmailTemplatesComponent/>
        </div>

        <div v-if="currentTab === 'autoPrint'">
          <AutoPrintingSettingsComponent/>
        </div>

        <div v-if="currentTab === 'settings'">
          <OtherSettingsComponent/>
        </div>

        <div v-if="currentTab === 'preview'">
          <PreviewComponent/>
        </div>

        <div v-if="currentTab === 'help'">
          <Help/>
        </div>
        <div v-if="currentTab === 'deliveryArea'">
          <DeliveryAreasComponent/>
        </div>

        <div v-if="currentTab === 'callCenter'">
          <CallCenterComponent/>
        </div>
        <div v-if="currentTab === 'status'">
          <OrderStatusesComponent/>
        </div>

      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import {ENDPOINTS} from '../../config'
import mixin from "../../mixins/KeyboardMixIns";
import Tabs from 'vue-tabs-with-active-line';
import GeneralInfoComponent from "./localbee/GeneralInfoComponent";
import DeliveryTimesComponent from "./localbee/DeliveryTimesComponent";
import DesignComponent from "./localbee/DesignComponent";
import PaymentOptionsComponent from "./localbee/PaymentOptionsComponent";
import ProductGroupsComponent from "./localbee/ProductGroupsComponent";
import ProductsComponent from "./localbee/ProductsComponent";
import OtherSettingsComponent from "./localbee/OtherSettingsComponent";
import PreviewComponent from "./localbee/PreviewComponent";
import Help from "./localbee/Help";
import QrCodeGenerator from "./localbee/QrCodeGenerator"
import DeliveryAreasComponent from "@/components/delivery/deliveryarea/DeliveryAreasComponent";
import CallCenterComponent from "@/components/delivery/settings/CallCenterComponent";
import OrderStatusesComponent from "@/components/delivery/settings/OrderStatusesComponent";
import SelfServiceDesign from "./localbee/SelfServiceDesign";
import EmailTemplatesComponent from "./localbee/EmailTemplatesComponent";
import AutoPrintingSettingsComponent from "./localbee/AutoPrintingSettingsComponent";


export default {
  name: "LocalBeeSettingsComponent",
  components: {
    AutoPrintingSettingsComponent,
    EmailTemplatesComponent,
    QrCodeGenerator,
    OrderStatusesComponent,
    CallCenterComponent,
    DeliveryAreasComponent,
    Help,
    PreviewComponent,
    OtherSettingsComponent,
    ProductsComponent,
    ProductGroupsComponent,
    PaymentOptionsComponent,
    DesignComponent,
    DeliveryTimesComponent,
    GeneralInfoComponent,
    SelfServiceDesign,
    Tabs
  },
  mixins: [mixin],
  data() {
    return {
      text: "example text",
      currentTab: 'generalInfo',
      ENDPOINTS,
      posName: null,
      taxID: null,
      streetNo: null,
      zip: null,
      city: null,
      country: null,
      globalSettings: true,
      // ----------------------------------

    }
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) { 
        this.currentTab = newTab;
        this.$router.push({query: {tab: newTab}})
      }
    },

  },
  computed: {
    tabs: function () {
      return [
        {
          title: this.$t('generic.lang_basicData'),
          value: 'generalInfo',
        },
        {
          title: 'Logos',
          value: 'logos',
        }
      ];
    }
  },
  mounted() {
    this.handleClick(this.$route.query.tab)
  },
}
</script>

<style scoped>
.tabs > .tabs__item, .tabs {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item {
  z-index: 1 !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

.items {
  cursor: pointer !important;
}

</style>
