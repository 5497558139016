<template>
  <v-card>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('generic.lang_provider')" class="active">

      </b-tab>
      <b-tab :title="$t('generic.lang_placetel')" class="inactive">

      </b-tab>
      <b-tab :title="$t('generic.lang_nav_help')" class="inactive">

      </b-tab>
    </b-tabs>
  </v-card>
</template>

<script>

export default {
name: "CallCenterComponent",
  components: {}
}
</script>

<style scoped>

</style>