<template>
  <v-card :loading="loading">
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('delivery.lang_delivery')" class="active">
        <!--
                <v-row justify="center">
                  <v-col cols="12" sm="6" md="6" lg="6" align="end">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="'PAYPAL CLIENT ID'"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  dense
                                  outlined
                                  required
                                  v-model="paypalID"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="'PAYPAL SECRET'"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  dense
                                  outlined
                                  type="password"
                                  v-model="paypalSecret"
                    ></v-text-field>
                  </v-col>
                </v-row>
                -->
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card class="pa-0">
                <v-data-table
                  :headers="dataTableHeader"
                  :items="deliveryPayments"
                >
                  <template v-slot:item.status="{item}">
                    <v-checkbox v-model="item.active">
                    </v-checkbox>
                  </template>
                  <template v-slot:item.crudEdit="{ item }">
                    <v-btn
                      elevation="0"
                      color="primary"
                      icon
                      @click="deliveryEditEntry(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="deliveryEditDialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ this.$t("tablebee.lang_edit_payment_method_translation") }}
                </span>
              </v-card-title>

              <v-card-text class="mt-3">
                <v-form
                  ref="deliveryPayementType"
                  lazy-validation
                  style="width: 100%"
                >
                  <b-tabs>
                    <b-tab active>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="DE" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.de"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="GB" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.en"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="FR" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.fr"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="SA" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.ar"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>
                  </b-tabs>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="cancelDeliveryEditDialog()"
                >
                  {{ $t("generic.lang_cancel") }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="editDeliveryPaymentsTranslation()"
                >
                  {{ $t("generic.lang_edit") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <v-divider class="ma-0"/>
        <v-item-group class="text-right">
          <v-btn large :loading="loading" @click="updateDeliveryPayment" color="success">
            {{$t('generic.lang_save')}}
          </v-btn>
        </v-item-group>
      </b-tab>
      <b-tab :title="$t('generic.lang_offSiteSale')" >
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card class="pa-0">
                <v-data-table
                  :headers="dataTableHeader"
                  :items="takeawayPayments"
                >
                  <template v-slot:item.status="{item}">
                    <v-checkbox v-model="item.active">
                    </v-checkbox>
                  </template>
                  <template v-slot:item.crudEdit="{ item }">
                    <v-btn
                      elevation="0"
                      color="primary"
                      icon
                      @click="takeawayEditEntry(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="takeawayEditDialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ this.$t("tablebee.lang_edit_payment_method_translation") }}
                </span>
              </v-card-title>

              <v-card-text class="mt-3">
                <v-form
                  ref="takeawayPayementType"
                  lazy-validation
                  style="width: 100%"
                >
                  <b-tabs>
                    <b-tab active>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="DE" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.de"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="GB" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.en"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="FR" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.fr"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <v-btn text class="ma-0">
                          <country-flag country="SA" />
                        </v-btn>
                      </template>

                      <v-row no-gutters>
                        <v-col class="" cols="12">
                          <v-subheader>
                            <strong>
                              {{
                                this.$t("accounting.lang_payment_method_") +
                                `: ${this.slectedPayement.paymentName}`
                              }}
                            </strong>
                          </v-subheader>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            flat
                            v-model="paymentType_translations.ar"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </b-tab>
                  </b-tabs>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="cancelTakeawayEditDialog()"
                >
                  {{ $t("generic.lang_cancel") }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="editTakeawayPaymentsTranslation()"
                >
                  {{ $t("generic.lang_edit") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <v-divider class="ma-0"/>
        <v-item-group class="text-right">
          <v-btn large :loading="loading" @click="updateTakeawayPayment" color="success">
            {{$t('generic.lang_save')}}
          </v-btn>
        </v-item-group>
      </b-tab>
    </b-tabs>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                             :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidth"
                            id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from '../../../config';
import CountryFlag from "vue-country-flag";

/*
 * 1    cash //euro-sign
 * 2    voucher
 * 3    EC //credit-card-blank
 * 4    bill //receipt
 * 5    ELV
 * 6    AUFS HAUS //hands-heart
 * 7    Split Payment *Special Case
 * 8    PayCard Payment (RFID) *Special Case
 * 9    CreditCard //credit-card
 * 10   Ticket Restaurant
 * 11   PayPal
 * 12   CustomerCard
 */

export default {
  name: "PaymentOptionsComponent",
  components: {
    CountryFlag,
  },
  mixins:[mixin],
  data(){
    return{
      ENDPOINTS,
      loading:false,
      deliveryEditDialog: false,
      takeawayEditDialog: false,
      slectedPayement: {},
      paymentType_translations: { de: "", en: "", fr: "", ar: "" },
      paypalID: null,
      paypalSecret: null,
      payments: {},
      dataTableHeader: [
        {
          text:this.$t('accounting.lang_payment_id'),
          value:'id',
          width:100,
        },
        {
          text: this.$t('accounting.lang_PaymentMethod'),
          value:'paymentName',
        },
        {
          text:this.$t('settings.lang_settings_activ'),
          value:'status',
        },
        {
          text: "",
          value: "crudEdit",
          align: "center",
          width: 100,
        },
      ],
      deliveryPayments: [
        {
          id:1,
          payment:this.$t('generic.lang_cash'),
          status:false,
        },
        {
          id:3,
          payment:this.$t('generic.lang_ec'),
          status:false,
        },
        {
          id:9,
          payment:this.$t('generic.lang_creditCard'),
          status:false,
        },
        {
          id:11,
          payment:'Paypal',
          status:false,
        },
        {
          id:25,
          payment:'Stripe',
          status:false,
        },
      ],
      takeawayPayments: [
        {
          id: 1,
          payment: this.$t("generic.lang_cash"),
          status: false,
        },
        {
          id: 3,
          payment: this.$t("generic.lang_ec"),
          status: false,
        },
        {
          id: 9,
          payment: this.$t("generic.lang_creditCard"),
          status: false,
        },
        {
          id: 11,
          payment: "Paypal",
          status: false,
        },
        {
          id: 25,
          payment: "Stripe",
          status: false,
        },
      ],
    };
  },
  computed: {},
  methods: {
    /*** Delivery ***/
    getDeliveryData() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.DELIVERY.GET)
        .then((res) => {
          this.deliveryPayments = res.data.formfillData.textFields;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
    updateDeliveryPayment() {
      this.loading = true;
      let data = [];
      if (Array.isArray(this.deliveryPayments))
        data = this.deliveryPayments.map(({ id, active }) => ({
          id: id,
          is_active: active,
        }));

      this.axios
        .post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.DELIVERY.UPDATE, {
          payments: data,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataSaved"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || res.data.status,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
    deliveryEditEntry(entry) {
      this.deliveryEditDialog = true;
      this.slectedPayement = entry;
      this.getDeliveryPaymentsTranslation();
    },
    cancelDeliveryEditDialog() {
      this.deliveryEditDialog = false;
      this.$refs.deliveryPayementType.reset();
    },
    getDeliveryPaymentsTranslation() {
      this.axios
        .post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.DELIVERY.GET)
        .then((res) => {
          if (res.status === 200) {
            res.data.formfillData.textFields.forEach((element) => {
              if (element["id"] == this.slectedPayement.id) {
                const lastKey = Object.keys(element).pop();

                this.paymentType_translations.de = element[lastKey].de;
                this.paymentType_translations.en = element[lastKey].en;
                this.paymentType_translations.fr = element[lastKey].fr;
                this.paymentType_translations.ar = element[lastKey].ar;
              }
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
    editDeliveryPaymentsTranslation() {
      let data = {};
      if (this.slectedPayement.id == "localbee_delivery_paypal_active") {
        data = {
          localbee_delivery_paypal_translations:
            this.paymentType_translations,
          localbee_delivery_pay_at_checkout_translations: null,
          localbee_delivery_secupay_translations: null,
          localbee_delivery_stripe_translations: null,
        };
      } else if (
        this.slectedPayement.id == "localbee_delivery_pay_at_checkout_active"
      ) {
        data = {
          localbee_delivery_paypal_translations: null,
          localbee_delivery_pay_at_checkout_translations:
            this.paymentType_translations,
          localbee_delivery_secupay_translations: null,
          localbee_delivery_stripe_translations: null,
        };
      } else if (
        this.slectedPayement.id == "localbee_delivery_secupay_active"
      ) {
        data = {
          localbee_delivery_paypal_translations: null,
          localbee_delivery_pay_at_checkout_translations: null,
          localbee_delivery_secupay_translations:
            this.paymentType_translations,
          localbee_delivery_stripe_translations: null,
        };
      } else if (
        this.slectedPayement.id == "localbee_delivery_stripe_active"
      ) {
        data = {
          localbee_delivery_paypal_translations: null,
          localbee_delivery_pay_at_checkout_translations: null,
          localbee_delivery_secupay_translations: null,
          localbee_delivery_stripe_translations:
            this.paymentType_translations,
        };
      }

      this.axios
        .post(
          ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.DELIVERY
            .PAYMENT_OPTIONS_TRANSLATIONS,
          data
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });

            this.deliveryEditDialog = false;
            this.$refs.deliveryPayementType.reset();
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },

    /*** Takeaway ***/
    getTakeawayData() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.TAKEAWAY.GET)
        .then((res) => {
          this.takeawayPayments = res.data.formfillData.textFields;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
    updateTakeawayPayment() {
      this.loading = true;
      let data = [];
      if (Array.isArray(this.takeawayPayments))
        data = this.takeawayPayments.map(({ id, active }) => ({
          id: id,
          is_active: active,
        }));

      this.axios
        .post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.TAKEAWAY.UPDATE, {
          payments: data,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataSaved"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || res.data.status,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
    takeawayEditEntry(entry) {
      this.takeawayEditDialog = true;
      this.slectedPayement = entry;
      this.getTakeawayPaymentsTranslation();
    },
    cancelTakeawayEditDialog() {
      this.takeawayEditDialog = false;
      this.$refs.takeawayPayementType.reset();
    },
    getTakeawayPaymentsTranslation() {
      this.axios
        .post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.TAKEAWAY.GET)
        .then((res) => {
          if (res.status === 200) {
            res.data.formfillData.textFields.forEach((element) => {
              if (element["id"] == this.slectedPayement.id) {
                const lastKey = Object.keys(element).pop();

                this.paymentType_translations.de = element[lastKey].de;
                this.paymentType_translations.en = element[lastKey].en;
                this.paymentType_translations.fr = element[lastKey].fr;
                this.paymentType_translations.ar = element[lastKey].ar;
              }
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
    editTakeawayPaymentsTranslation() {
      let data = {};
      if (this.slectedPayement.id == "localbee_takeaway_paypal_active") {
        data = {
          localbee_takeaway_paypal_translations:
            this.paymentType_translations,
          localbee_takeaway_pay_at_checkout_translations: null,
          localbee_takeaway_secupay_translations: null,
          localbee_takeaway_stripe_translations: null,
        };
      } else if (
        this.slectedPayement.id == "localbee_takeaway_pay_at_checkout_active"
      ) {
        data = {
          localbee_takeaway_paypal_translations: null,
          localbee_takeaway_pay_at_checkout_translations:
            this.paymentType_translations,
          localbee_takeaway_secupay_translations: null,
          localbee_takeaway_stripe_translations: null,
        };
      } else if (
        this.slectedPayement.id == "localbee_takeaway_secupay_active"
      ) {
        data = {
          localbee_takeaway_paypal_translations: null,
          localbee_takeaway_pay_at_checkout_translations: null,
          localbee_takeaway_secupay_translations:
            this.paymentType_translations,
          localbee_takeaway_stripe_translations: null,
        };
      } else if (
        this.slectedPayement.id == "localbee_takeaway_stripe_active"
      ) {
        data = {
          localbee_takeaway_paypal_translations: null,
          localbee_takeaway_pay_at_checkout_translations: null,
          localbee_takeaway_secupay_translations: null,
          localbee_takeaway_stripe_translations:
            this.paymentType_translations,
        };
      }

      this.axios
        .post(
          ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.PAYMENT.TAKEAWAY
            .PAYMENT_OPTIONS_TRANSLATIONS,
          data
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });

            this.takeawayEditDialog = false;
            this.$refs.takeawayPayementType.reset();
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
  },
  mounted() {
    this.getDeliveryData();
    this.getTakeawayData();
  },
};
</script>
