import { render, staticRenderFns } from "./PreviewComponent.vue?vue&type=template&id=fe2aca22&scoped=true"
import script from "./PreviewComponent.vue?vue&type=script&lang=js"
export * from "./PreviewComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe2aca22",
  null
  
)

export default component.exports